<template>
  <div class="cart-page">
    <div :class="$i18n.locale" v-if="hasProducts">
      <!-- cart component page  -->
      <!-- first rule check if theres data or not  -->
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-start pl-0">
          <li class="breadcrumb-item">
            <router-link to="/">
              {{ $t("items.home") }}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/cart" class="main-color">
              {{ $t("cart.purchaseCart") }}
            </router-link>
          </li>
        </ol>
      </nav>

      <div class="my-5">
        <div class="row justify-content-between align-items-center">
          <div class="px-2" v-if="cartLength">
            <h2 class="text-dark">
              {{ $t("profile.myCart") }}({{ cartLength }}):
            </h2>
          </div>
          <!-- <div class="px-2">
            <p class="text-dark" role="button" @click="clearAll">
              <ins>{{$t('profile.clearAll')}}</ins>
            </p>
          </div> -->
        </div>
      </div>

      <div class="steps mt-4">
        <div class="step1 my-3">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="step1">
                <h4 class="text-dark">
                  {{ $t("profile.step1") }}: {{ $t("profile.contactInfo") }}
                </h4>
                <!-- user data for checkout  -->

                <div class="payment w-100">
                  <div class="payment py-3">
                    <div class="">
                      <div class="content">
                        <div class="row payment-data">
                          <div class="col-12 payment-delivery">
                            <!-- <div
                            class="d-flex justify-content-between heading align-items-center mb-4"
                          >
                            <span class="title">{{
                              $t("payment.deliveryData")
                            }}</span>
                          </div> -->
                            <form class="row delivery-form">
                              <div class="col-6 form-group required">
                                <label for="firstName">{{
                                  $t("payment.firstName")
                                }}</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="firstName"
                                  v-model="paymentFormData.first_name"
                                />
                                <div
                                  class="error text-start"
                                  v-for="(error, index) in errors.first_name"
                                  :key="index"
                                >
                                  {{ error }}
                                </div>
                              </div>
                              <div class="col-6 form-group required">
                                <label for="firstName">{{
                                  $t("payment.lastName")
                                }}</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="lastName"
                                  v-model="paymentFormData.last_name"
                                />
                                <div
                                  class="error text-start"
                                  v-for="(error, index) in errors.last_name"
                                  :key="index"
                                >
                                  {{ error }}
                                </div>
                              </div>

                              <div class="col-md-5 col-sm-12 form-group">
                                <label for="email"
                                  >{{ $t("payment.email") }}
                                  <span
                                    class="requried text-danger"
                                    v-if="buyerUserData"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                  v-model="paymentFormData.email"
                                />
                                <div
                                  class="error text-start"
                                  v-for="(error, index) in errors.email"
                                  :key="index"
                                >
                                  {{ error }}
                                </div>
                              </div>

                              <b-col md="3" sm="12">
                                <b-form-group>
                                  <label for="countryCode">{{
                                    $t("register.countryCode")
                                  }}</label>
                                  <span class="requried text-danger">*</span>

                                  <b-form-select
                                    v-model="paymentFormData.country_code"
                                    @change="paymentFormData.phone = null"
                                  >
                                    <b-form-select-option value="null" disabled
                                      >{{ $t("register.countryCode") }}
                                      <span class="requried text-danger"
                                        >*</span
                                      >
                                    </b-form-select-option>
                                    <b-form-select-option
                                      v-for="(country, index) in countries"
                                      :key="index"
                                      v-bind="{
                                        selected:
                                          selectedPhonePrefix.id == country.id,
                                      }"
                                      :value="country.phone_prefix"
                                    >
                                      {{ country.title }}
                                      {{ country.phone_prefix }}
                                    </b-form-select-option>
                                  </b-form-select>

                                  <div
                                    class="error"
                                    v-for="(
                                      error, index
                                    ) in errors.country_code"
                                    :key="index"
                                  >
                                    {{ error }}
                                  </div>
                                </b-form-group>
                              </b-col>

                              <div
                                class="col-md-4 col-sm-12 form-group required"
                              >
                                <label for="phoneNumber">{{
                                  $t("payment.phoneNumber")
                                }}</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="phoneNumber"
                                  v-model="paymentFormData.phone"
                                  :placeholder="paymentFormData.phone"
                                />
                                <div
                                  class="error text-start"
                                  v-for="(error, index) in errors.phone"
                                  :key="index"
                                >
                                  {{ error }}
                                </div>
                              </div>

                              <div
                                class="col-12 form-group custom-control custom-checkbox"
                                v-if="!buyerUserData"
                              ></div>
                              <div class="col-12 form-group">
                                <label for="notes">
                                  {{ $t("payment.notes") }} ({{
                                    $t("payment.optional")
                                  }})
                                </label>
                                <textarea
                                  class="form-control"
                                  id="notes"
                                  rows="3"
                                  v-model="paymentFormData.comment"
                                ></textarea>
                                <div
                                  class="error text-start"
                                  v-for="(error, index) in errors.comment"
                                  :key="index"
                                >
                                  {{ error }}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- end user data for checkout  -->
              </div>

              <!-- step 2 shipping addresses  -->
              <div class="step2 my-3">
                <div class="" v-if="!loading">
                  <h4 class="text-dark">
                    {{ $t("profile.step2") }} : {{ $t("payment.delivery") }}
                  </h4>
                  <div class="row">
                    <div class="col-12 order-shipping">
                      <div :class="$i18n.locale">
                        <div class="shipping-holder">
                          <div class="">
                            <div class="row">
                              <div class="col-12">
                                <div class="addresses-holder">
                                  <div class="addresses">
                                    <form>
                                      <!-- add new address  -->
                                      <label>
                                        <input
                                          type="radio"
                                          value="newAddress"
                                          name="radio"
                                          v-model="selectAddressShape"
                                          class="GuestNewAddress"
                                        />
                                        <span>{{
                                          $t("profile.newAddress")
                                        }}</span>
                                      </label>

                                      <!-- select from existing addresses  -->
                                      <label
                                        v-if="
                                          buyerUserData &&
                                          addresses &&
                                          addresses.length != 0
                                        "
                                      >
                                        <input
                                          type="radio"
                                          value="existingAddresses"
                                          name="radio"
                                          v-model="selectAddressShape"
                                          class="existingAddresses"
                                        />
                                        <span>{{
                                          $t("payment.selectExist")
                                        }}</span>
                                      </label>

                                      <!-- existing addresses if it exist  -->
                                      <!-- <span
                                        v-if="
                                          selectAddressShape ===
                                            'existingAddresses' &&
                                          addresses &&
                                          addresses.length != 0
                                        "
                                      >
                                        <b-form-select
                                          v-model="selectedAddress"
                                          class="pickupAddresses"
                                          @change="changeAddress"
                                        >
                                          <b-form-select-option
                                            selected
                                            disabled
                                            value="null"
                                            >{{
                                              $t("payment.selectExist")
                                            }}</b-form-select-option
                                          >

                                          <b-form-select-option
                                            v-for="(
                                              address, index
                                            ) in addresses"
                                            :key="index"
                                            :value="address"
                                          >
                                            <span
                                              class="mb-2"
                                              v-if="address.apartment"
                                              >{{ address.apartment }} ,</span
                                            >
                                            <span
                                              class="mb-2"
                                              v-if="address.floor"
                                              >{{ address.floor }} ,</span
                                            >
                                            <span
                                              class="mb-2"
                                              v-if="address.address_line1"
                                              >{{
                                                address.address_line1
                                              }}
                                              ,</span
                                            >
                                            <span
                                              class="mb-2"
                                              v-if="address.address_line2"
                                              >{{
                                                address.address_line2
                                              }}
                                              ,</span
                                            >

                                            <span
                                              class="mb-2"
                                              v-if="address.city"
                                              >{{ address.city.title }} ,</span
                                            >
                                            <span
                                              class="mb-2"
                                              v-if="address.region"
                                              >{{
                                                address.region.title
                                              }}
                                              ,</span
                                            >

                                            <span
                                              class="mb-2"
                                              v-if="address.country"
                                              >{{ address.country.title }}</span
                                            >
                                          </b-form-select-option>
                                        </b-form-select>
                                      </span> -->
                                    </form>
                                  </div>
                                  <!-- add new address data if you select add new address  -->
                                  <div class="addressShape" v-if="expanded">
                                    <div
                                      class="newAddress mt-5"
                                      v-if="selectAddressShape === 'newAddress'"
                                    >
                                      <form class="account-information-form">
                                        <b-row class="justify-content-start">
                                          <!-- country  -->
                                          <b-col
                                            lg="4"
                                            v-if="form.country_id !== undefined"
                                          >
                                            <b-form-group>
                                              <!-- <label>{{ $t("profile.country") }}</label> -->
                                              <b-form-select
                                                v-model="form.country_id"
                                                @input="getAllRegions"
                                              >
                                                <b-form-select-option
                                                  value="null"
                                                  disabled
                                                  >{{ $t("profile.country") }}
                                                  <span
                                                    class="requried text-danger"
                                                    >*</span
                                                  >
                                                </b-form-select-option>
                                                <b-form-select-option
                                                  v-for="(
                                                    country, index
                                                  ) in countries"
                                                  :key="index"
                                                  :value="country.id"
                                                  >{{ country.title }}
                                                </b-form-select-option>
                                              </b-form-select>
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.country_id"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>

                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.country"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                              <div
                                                class="error"
                                                v-if="
                                                  localClicked &&
                                                  form.country_id == null
                                                "
                                              >
                                                {{
                                                  $t("payment.CountryRequired")
                                                }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- regions -->
                                          <b-col
                                            lg="4"
                                            v-if="form.region_id !== undefined"
                                          >
                                            <b-form-group>
                                              <!-- <label>{{ $t("profile.region") }}</label>
                                  <span class="requried">*</span> -->
                                              <b-form-select
                                                v-model="form.region_id"
                                                :disabled="!form.country_id"
                                                @input="getAllCities"
                                              >
                                                <b-form-select-option
                                                  value="null"
                                                  disabled
                                                  >{{ $t("profile.region") }}
                                                  <span
                                                    class="requried text-danger"
                                                    >*</span
                                                  >
                                                </b-form-select-option>
                                                <b-form-select-option
                                                  v-for="(
                                                    region, index
                                                  ) in regions"
                                                  :key="index"
                                                  :value="region.id"
                                                  >{{ region.title }}
                                                </b-form-select-option>
                                              </b-form-select>
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.region_id"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>

                                              <div
                                                class="error"
                                                v-if="
                                                  localClicked &&
                                                  form.region_id == null
                                                "
                                              >
                                                {{
                                                  $t("payment.RegionRequired")
                                                }}
                                              </div>

                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.governorate"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- cities -->
                                          <b-col
                                            lg="4"
                                            v-if="form.city_id !== undefined"
                                          >
                                            <b-form-group>
                                              <!-- <label>{{ $t("profile.city") }}</label>
                                  <span class="requried text-danger">*</span> -->
                                              <b-form-select
                                                v-model="form.city_id"
                                                :disabled="
                                                  !form.country_id ||
                                                  !form.region_id
                                                "
                                              >
                                                <b-form-select-option
                                                  value="null"
                                                  disabled
                                                  >{{ $t("profile.city") }}
                                                  <span
                                                    class="requried text-danger"
                                                    >*</span
                                                  >
                                                </b-form-select-option>
                                                <b-form-select-option
                                                  v-for="(
                                                    city, index
                                                  ) in cities"
                                                  :key="index"
                                                  :value="city.id"
                                                  >{{ city.title }}
                                                </b-form-select-option>
                                              </b-form-select>

                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.city_id"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.city"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                              <div
                                                class="error"
                                                v-if="
                                                  localClicked &&
                                                  form.city_id == null
                                                "
                                              >
                                                {{ $t("payment.CityRequired") }}
                                              </div>
                                            </b-form-group>
                                          </b-col>

                                          <!-- address_line_1  -->
                                          <b-col
                                            lg="6"
                                            v-if="
                                              form.address_line_1 !== undefined
                                            "
                                          >
                                            <b-form-group>
                                              <!-- <label for="streetNumber">{{
                                    $t("profile.streetNumber")
                                  }}</label> -->
                                              <!-- <span class="requried">*</span> -->
                                              <b-form-input
                                                id="streetNumber"
                                                v-model="form.address_line_1"
                                                :placeholder="
                                                  $t('contactUs.address') + '*'
                                                "
                                              />
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.address_line_1"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                              <div
                                                class="error"
                                                v-if="
                                                  localClicked &&
                                                  form.address_line_1 == null
                                                "
                                              >
                                                {{
                                                  $t("payment.AddressRequired")
                                                }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- street  (new add)-->
                                          <b-col
                                            lg="6"
                                            v-if="form.street !== undefined"
                                          >
                                            <b-form-group>
                                              <b-form-input
                                                id="street"
                                                v-model="form.street"
                                                :placeholder="
                                                  $t(
                                                    'profile.newStreetNumber'
                                                  ) + '*'
                                                "
                                              />
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.street"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- name in english (new add)-->
                                          <b-col
                                            lg="6"
                                            v-if="
                                              $i18n.locale == 'en' &&
                                              buyerUserData &&
                                              form.name !== undefined
                                            "
                                          >
                                            <b-form-group>
                                              <b-form-select
                                                v-model="form.name"
                                              >
                                                <b-form-select-option
                                                  value="null"
                                                  disabled
                                                  >{{ $t("profile.name") }}
                                                  <span
                                                    class="requried text-danger"
                                                    >*</span
                                                  >
                                                </b-form-select-option>
                                                <b-form-select-option
                                                  v-for="(
                                                    formName, index
                                                  ) in en_formNames"
                                                  :key="index"
                                                  :value="formName"
                                                  >{{ formName }}
                                                </b-form-select-option>
                                              </b-form-select>
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.name"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- name in arabic (new add)-->
                                          <b-col
                                            lg="6"
                                            v-if="
                                              $i18n.locale == 'ar' &&
                                              buyerUserData &&
                                              form.name !== undefined
                                            "
                                          >
                                            <b-form-group
                                              v-if="arabicAvailable !== 'no'"
                                            >
                                              <b-form-select
                                                v-model="form.name"
                                              >
                                                <b-form-select
                                                  value="null"
                                                  disabled
                                                  >{{
                                                    $t("profile.name")
                                                  }}</b-form-select
                                                >
                                                <b-form-select-option
                                                  v-for="(
                                                    formName, index
                                                  ) in ar_formNames"
                                                  :key="index"
                                                  :value="formName"
                                                  >{{ formName }}
                                                </b-form-select-option>
                                              </b-form-select>
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.name"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- floor  -->
                                          <b-col
                                            lg="6"
                                            v-if="form.floor !== undefined"
                                          >
                                            <b-form-group>
                                              <b-form-input
                                                id="floor"
                                                v-model="form.floor"
                                                :placeholder="
                                                  $t('profile.floor')
                                                "
                                              />
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.floor"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- avenue (new add)  -->
                                          <b-col
                                            lg="6"
                                            v-if="form.avenue !== undefined"
                                          >
                                            <b-form-group>
                                              <b-form-input
                                                id="floor"
                                                v-model="form.avenue"
                                                :placeholder="
                                                  $t('profile.avenue')
                                                "
                                              />
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.avenue"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- home number  -->
                                          <b-col
                                            lg="6"
                                            v-if="
                                              form.building_number !== undefined
                                            "
                                          >
                                            <b-form-group>
                                              <!-- <label for="homeNumber">{{
                                    $t("profile.homeNumber")
                                  }}</label> -->
                                              <!-- <span class="requried">*</span> -->
                                              <b-form-input
                                                id="homeNumber"
                                                v-model="form.building_number"
                                                :placeholder="
                                                  $t('profile.homeNumber')
                                                "
                                              />
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.building_number"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>

                                          <!-- block number   -->
                                          <b-col
                                            lg="6"
                                            v-if="form.block !== undefined"
                                          >
                                            <b-form-group>
                                              <b-form-input
                                                id="blockNumber"
                                                v-model="form.block"
                                                :placeholder="
                                                  $t('profile.blockNumber')
                                                "
                                              />
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.block"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <!-- post code  -->
                                          <b-col
                                            lg="6"
                                            v-if="form.pin_code !== undefined"
                                          >
                                            <b-form-group>
                                              <b-form-input
                                                id="postCode"
                                                type="number"
                                                v-model="form.pin_code"
                                                :formatter="formatPin_code"
                                                :placeholder="
                                                  $t('profile.zipCode')
                                                "
                                              />
                                              <div
                                                class="error"
                                                v-if="postalError"
                                              >
                                                {{ $t("payment.postalError") }}
                                              </div>
                                              <div
                                                class="error"
                                                v-for="(
                                                  error, index
                                                ) in errors.pin_code"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col
                                            lg="12"
                                            v-if="form.notes !== undefined"
                                          >
                                            <textarea
                                              class="form-control"
                                              id="notes"
                                              rows="3"
                                              :placeholder="$t('payment.notes')"
                                              v-model="form.notes"
                                            ></textarea>
                                            <div
                                              class="error"
                                              v-for="(
                                                error, index
                                              ) in errors.notes"
                                              :key="index"
                                            >
                                              {{ error }}
                                            </div>
                                          </b-col>

                                          <b-col lg="12">
                                            <div v-if="dynamicInputs">
                                              <dynamicComponent
                                                :dynamicInputs="dynamicInputs"
                                                :form="form"
                                                :errors="errors"
                                              />
                                            </div>
                                          </b-col>
                                        </b-row>

                                        <b-button
                                          v-if="buyerUserData"
                                          type="submit"
                                          @click.prevent="createAdress()"
                                          class="login-button bg-main m-0 mt-4 py-3 px-5 text-white text-center w-auto"
                                        >
                                          {{ $t("register.submit") }} &
                                          {{ $t("cart.checkFees") }}
                                        </b-button>
                                        <b-button
                                          v-else
                                          type="submit"
                                          @click.prevent="localStoreAdresses()"
                                          class="login-button bg-main m-0 mt-4 py-3 px-5 text-white text-center w-auto mx-2"
                                        >
                                          {{ $t("register.submit") }} &
                                          {{ $t("cart.checkFees") }}
                                        </b-button>
                                      </form>
                                    </div>
                                  </div>

                                  <!-- close icon select add new address  -->

                                  <div
                                    class="close-options"
                                    v-if="
                                      expanded &&
                                      selectAddressShape === 'newAddress'
                                    "
                                    @click="expanded = !expanded"
                                  >
                                    <font-awesome-icon
                                      icon="fa-solid fa-xmark"
                                    />
                                  </div>
                                  <!-- open add new address dropdown (option )  -->
                                  <div
                                    class="close-options"
                                    v-else-if="!expanded"
                                    @click="expanded = !expanded"
                                  >
                                    <font-awesome-icon
                                      icon="fa-solid fa-arrow-down"
                                    />
                                  </div>
                                </div>
                                <div
                                  class="addresses-holder mt-5 shopping-cart-page"
                                  v-if="
                                    selectAddressShape ===
                                      'existingAddresses' &&
                                    addresses &&
                                    addresses.length != 0
                                  "
                                >
                                  <b-row v-if="loading" class="px-5">
                                    <b-col
                                      lg="3"
                                      sm="6"
                                      v-for="x in 10"
                                      :key="x"
                                    >
                                      <b-skeleton-img></b-skeleton-img>
                                      <b-card>
                                        <b-skeleton
                                          animation="fade"
                                          width="60%"
                                          class="border-none"
                                        ></b-skeleton>
                                        <b-skeleton
                                          animation="fade"
                                          width="85%"
                                          class="border-none"
                                        ></b-skeleton>
                                      </b-card>
                                    </b-col>
                                  </b-row>
                                  <!-- data comes from backend  -->
                                  <VueSlickCarousel
                                    v-bind="settings"
                                    v-if="addresses && addresses.length"
                                  >
                                    <div
                                      v-for="(address, index) in addresses"
                                      :key="index"
                                      class="slider-data"
                                      :class="{
                                        'new-border-main':
                                          selectedAddress.uuid == address.uuid,
                                      }"
                                      @click.prevent="
                                        selectedAddress = address;
                                        changeAddress();
                                      "
                                    >
                                      <div
                                        class="sign my-2 d-flex align-items-center"
                                      >
                                        <span
                                          :class="{
                                            'main-color':
                                              selectedAddress.uuid ==
                                              address.uuid,
                                          }"
                                          ><font-awesome-icon
                                            icon="fa-regular fa-circle-check"
                                            size="2x"
                                        /></span>
                                        <span
                                          class="h6 mx-2"
                                          :class="{
                                            'main-color':
                                              selectedAddress.uuid ==
                                              address.uuid,
                                          }"
                                          >{{
                                            $t("cart.deleiverToAddress")
                                          }}</span
                                        >
                                      </div>
                                      <div class="address-data">
                                        <div class="row mb-2">
                                          <div
                                            class="col-md-6 col-sm-12"
                                            v-if="address.country"
                                          >
                                            <div>
                                              {{ $t("profile.country") }} :
                                              {{ address.country.title }}
                                            </div>
                                          </div>
                                          <div
                                            class="col-md-6 col-sm-12"
                                            v-if="address.region"
                                          >
                                            <div>
                                              {{ $t("profile.region") }} :
                                              {{ address.region.title }}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row mb-2">
                                          <div
                                            class="col-md-6 col-sm-12"
                                            v-if="address.city"
                                          >
                                            <div>
                                              {{ $t("profile.city") }} :
                                              {{ address.city.title }}
                                            </div>
                                          </div>
                                          <div
                                            class="col-md-6 col-sm-12"
                                            v-if="address.block"
                                          >
                                            <div>
                                              {{ $t("profile.block_number") }} :
                                              {{ address.block }}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row mb-2">
                                          <div
                                            class="col-md-6 col-sm-12"
                                            v-if="address.street"
                                          >
                                            <div>
                                              {{
                                                $t("profile.newStreetNumber")
                                              }}
                                              : {{ address.street }}
                                            </div>
                                          </div>
                                          <div
                                            class="col-md-6 col-sm-12"
                                            v-if="address.floor"
                                          >
                                            <div>
                                              {{ $t("profile.floor") }} :
                                              {{ address.floor }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </VueSlickCarousel>
                                </div>
                              </div>
                              <!-- <div class="" v-else></div> -->
                            </div>
                          </div>
                        </div>
                        <!-- {{ ratingNum }} -->
                      </div>
                    </div>
                  </div>
                </div>

                <!-- second : when page loading  -->

                <div
                  class="d-flex justify-content-center align-items-center flex-column"
                  v-else
                >
                  <img
                    src="@/assets/images/BeanLoading2.gif"
                    alt="cart-image"
                    class="w-25"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="step2 my-3">
                <div class="" v-if="!loading">
                  <div class="row">
                    <div class="cart w-100">
                      <div class="cart-detail p-4">
                        <div class="">
                          <div class="">
                            <!-- display all coasts data  -->

                            <div class="data">
                              <!-- start add coupon  -->
                              <div class="coupon-holder mb-3">
                                <h4 class="text-dark">
                                  {{ $t("payment.haveCoupon") }}
                                </h4>
                                <div class="">
                                  <div class="">
                                    <div class="cart">
                                      <div class="cart-table">
                                        <div class="">
                                          <div class="input-holder">
                                            <form @submit.prevent="addCoupon">
                                              <!-- coupon input  -->

                                              <input
                                                type="text"
                                                :placeholder="
                                                  $t('cart.addCoupon')
                                                "
                                                class="my-2 h-100 p-3 w-100 itemInput"
                                                v-model="couponText"
                                                :disabled="validCoupon"
                                              />
                                              <span
                                                :title="$t('cart.enableButton')"
                                                class="close"
                                                >x</span
                                              >
                                              <b-button
                                                type="submit"
                                                class="login-button my-2 py-3 px-4 w-auto"
                                                :disabled="validCoupon"
                                              >
                                                <!-- <span>{{ $t("cart.couponDiscount") }}</span> -->
                                                <span>{{
                                                  $t("payment.Apply")
                                                }}</span>
                                              </b-button>
                                            </form>
                                          </div>

                                          <!-- button dosnt work if input is empty  -->
                                        </div>

                                        <!-- display coupon if valid  -->

                                        <h6
                                          class="couponValid text-success m-0 p-0"
                                        ></h6>
                                        <h6
                                          class="couponNotValid text-danger m-0 p-0"
                                        ></h6>

                                        <!-- display error if coupon is invalid  -->
                                        <div
                                          class="error text-center"
                                          v-if="couponError"
                                        >
                                          {{ couponError }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="valid-coupons text-center">
                                    <h5
                                      v-if="coupons && coupons.length > 0"
                                      class="text-center"
                                    >
                                      {{ $t("cart.validCoupons") }}
                                    </h5>
                                    <!-- list valid coupons  -->
                                    <ul
                                      class="unstyled-order coupons-data-holder"
                                    >
                                      <li
                                        v-for="(coupon, index) in coupons"
                                        :key="index"
                                      >
                                        <span class="couponValue"
                                          >{{ coupon.title }}
                                        </span>
                                        <span
                                          class="removeCoupon"
                                          @click="removeMyCoupon(coupon, index)"
                                          >x</span
                                        >
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <!-- end add coupon  -->
                              <table class="w-100">
                                <tbody>
                                  <tr>
                                    <th>{{ $t("profile.subTotal") }}</th>
                                    <td
                                      v-if="cart_sub_total"
                                      :class="{
                                        'float-right': $i18n.locale == 'en',
                                        'float-left': $i18n.locale == 'ar',
                                      }"
                                    >
                                      {{ cart_sub_total | fixedCurrency }}
                                      {{ currency }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t("cart.discount") }}</th>
                                    <td
                                      v-if="
                                        totalDiscount !== null && cart_sub_total
                                      "
                                      :class="{
                                        'float-right': $i18n.locale == 'en',
                                        'float-left': $i18n.locale == 'ar',
                                      }"
                                    >
                                      <span v-if="totalDiscountReplacement == 0"
                                        >-</span
                                      >
                                      <span v-else>
                                        {{
                                          totalDiscountReplacement
                                            | fixedCurrency
                                        }}
                                        {{ currency }}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>{{ $t("cart.deleiveryFees") }}</th>
                                    <td
                                      v-if="shippingCartFee !== null"
                                      :class="{
                                        'float-right': $i18n.locale == 'en',
                                        'float-left': $i18n.locale == 'ar',
                                      }"
                                    >
                                      <span v-if="shippingCartFee == 0">-</span>
                                      <span v-else
                                        >{{ shippingCartFee | fixedCurrency }}
                                        {{ currency }}</span
                                      >
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>
                                      <h4 class="main-color">
                                        {{ $t("cart.total") }}
                                      </h4>
                                    </th>
                                    <td
                                      v-if="totalPayment"
                                      :class="{
                                        'float-right': $i18n.locale == 'en',
                                        'float-left': $i18n.locale == 'ar',
                                      }"
                                    >
                                      <h4 class="main-color">
                                        {{
                                          totalPaymentReplacement
                                            | fixedCurrency
                                        }}
                                        {{ currency }}
                                      </h4>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!-- display payment methods  -->

                              <div class="">
                                <div class="payment w-100">
                                  <div class="payment">
                                    <div class="">
                                      <div class="content">
                                        <div class="payment-data">
                                          <div class="payment-method">
                                            <div class="heading mb-3">
                                              <span class="title"
                                                >choose your payment
                                                method:</span
                                              >
                                            </div>
                                            <div class="methods-data">
                                              <div class="methods">
                                                <!-- display when wallet amount equal or more than cart coast  -->
                                                <div
                                                  class="method coupon"
                                                  v-if="
                                                    buyerUserData &&
                                                    totalPaymentReplacement <=
                                                      0 &&
                                                    couponMethodAvailable ==
                                                      true
                                                  "
                                                >
                                                  <div
                                                    class="custom-control custom-radio custom-control-inline"
                                                  >
                                                    <input
                                                      type="radio"
                                                      id="paymentMethod0"
                                                      name="paymentMethod"
                                                      class="custom-control-input"
                                                      v-model="
                                                        paymentFormData.payment_type
                                                      "
                                                      value="coupon"
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      for="paymentMethod0"
                                                    >
                                                      {{
                                                        $t(
                                                          "cart.couponDiscount"
                                                        )
                                                      }}
                                                      <sup>*</sup>
                                                    </label>
                                                  </div>
                                                </div>

                                                <!-- display when wallet amount equal or more than cart coast  -->
                                                <div
                                                  class="method wallet"
                                                  v-if="
                                                    walletData > 0 &&
                                                    buyerUserData &&
                                                    walletData >=
                                                      totalPaymentReplacement &&
                                                    walletMethodAvailable ==
                                                      true
                                                  "
                                                >
                                                  <div
                                                    class="custom-control custom-radio custom-control-inline"
                                                  >
                                                    <input
                                                      type="radio"
                                                      id="paymentMethod0"
                                                      name="paymentMethod"
                                                      class="custom-control-input"
                                                      v-model="
                                                        paymentFormData.payment_type
                                                      "
                                                      value="wallet"
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      for="paymentMethod0"
                                                    >
                                                      {{ $t("profile.wallet") }}
                                                      <sup>*</sup>
                                                    </label>
                                                    <span
                                                      >{{ walletData }}
                                                      {{ currency }}</span
                                                    >
                                                  </div>
                                                </div>
                                                <!-- display when wallet less than cart coast  -->
                                                <div
                                                  class="method wallet_visa"
                                                  v-if="
                                                    walletData > 0 &&
                                                    buyerUserData &&
                                                    walletData <
                                                      totalPaymentReplacement &&
                                                    walletVisaMethodAvailable ==
                                                      true
                                                  "
                                                >
                                                  <div
                                                    class="custom-control custom-radio custom-control-inline"
                                                  >
                                                    <input
                                                      type="radio"
                                                      id="paymentMethod5"
                                                      name="paymentMethod"
                                                      class="custom-control-input"
                                                      v-model="
                                                        paymentFormData.payment_type
                                                      "
                                                      value="wallet_visa"
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      for="paymentMethod5"
                                                    >
                                                      {{ $t("profile.wallet") }}
                                                      <!-- {{ $t("profile.wallet_visa") }} -->
                                                      <sup>*</sup>
                                                    </label>
                                                    <span
                                                      >{{ walletData }}
                                                      {{ currency }}</span
                                                    >
                                                    <p>
                                                      {{
                                                        $t("profile.remainKnet")
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                                <!-- bank option  -->
                                                <div
                                                  class="method bank"
                                                  v-if="
                                                    buyerUserData &&
                                                    bankMethodAvailable ==
                                                      true &&
                                                    paymentMethods.bankTransfer
                                                  "
                                                >
                                                  <div
                                                    class="custom-control custom-radio custom-control-inline"
                                                  >
                                                    <input
                                                      type="radio"
                                                      id="paymentMethod1"
                                                      v-b-modal.bankModal
                                                      name="paymentMethod"
                                                      class="custom-control-input"
                                                      v-model="
                                                        paymentFormData.payment_type
                                                      "
                                                      value="bank"
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      for="paymentMethod1"
                                                    >
                                                      {{
                                                        $t(
                                                          "payment.bankTransfer"
                                                        )
                                                      }}
                                                      <sup>*</sup>
                                                    </label>
                                                    <span>{{
                                                      $t(
                                                        "payment.paymentByBank"
                                                      )
                                                    }}</span>
                                                  </div>
                                                  <p
                                                    class="error text-center"
                                                    v-if="
                                                      paymentFormData.payment_type ===
                                                        'bank' &&
                                                      paymentFormData.file ==
                                                        null
                                                    "
                                                  >
                                                    {{
                                                      $t(
                                                        "profile.filePlaceHolder"
                                                      )
                                                    }}
                                                  </p>
                                                  <div
                                                    class="error text-center"
                                                    v-for="(
                                                      error, index
                                                    ) in errors.file"
                                                    :key="index"
                                                  >
                                                    {{ error }}
                                                  </div>
                                                </div>
                                                <!-- cach option  -->
                                                <div
                                                  class="method cach"
                                                  v-if="
                                                    cachMethodAvailable ==
                                                      true &&
                                                    paymentMethods.cashOnDelivery
                                                  "
                                                >
                                                  <div
                                                    class="custom-control custom-radio custom-control-inline"
                                                  >
                                                    <input
                                                      type="radio"
                                                      id="paymentMethod2"
                                                      name="paymentMethod"
                                                      class="custom-control-input"
                                                      v-model="
                                                        paymentFormData.payment_type
                                                      "
                                                      value="cach"
                                                    />
                                                    <label
                                                      class="custom-control-label"
                                                      for="paymentMethod2"
                                                    >
                                                      {{
                                                        $t(
                                                          "payment.paymentWhenReceiving"
                                                        )
                                                      }}
                                                      <sup>*</sup>
                                                    </label>
                                                    <span>{{
                                                      $t(
                                                        "payment.requestReceipt"
                                                      )
                                                    }}</span>
                                                  </div>
                                                </div>
                                                <!-- visa option ( online payment)  -->
                                                <div
                                                  class="method visa row justify-content-between align-content-center"
                                                  v-if="
                                                    visaMethodAvailable ==
                                                      true &&
                                                    paymentMethods.onlinePayment &&
                                                    onlinePaymentGuestSettingControl
                                                  "
                                                >
                                                  <div
                                                    class="col-md-8 col-xs-12"
                                                  >
                                                    <div
                                                      class="custom-control custom-radio custom-control-inline"
                                                    >
                                                      <input
                                                        type="radio"
                                                        id="paymentMethod3"
                                                        name="paymentMethod"
                                                        class="custom-control-input"
                                                        v-model="
                                                          paymentFormData.payment_type
                                                        "
                                                        value="visa"
                                                      />
                                                      <label
                                                        class="custom-control-label"
                                                        for="paymentMethod3"
                                                      >
                                                        {{
                                                          $t(
                                                            "payment.onlinePayment"
                                                          )
                                                        }}
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="col-md-4 col-xs-12"
                                                  >
                                                    <div class="online-media">
                                                      <img
                                                        src="@/assets/images/cart.png"
                                                        alt=""
                                                        srcset=""
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <!-- boubyan  -->
                                                <div
                                                  class="method visa row justify-content-between align-content-center"
                                                  v-if="
                                                    boubyanPaymentGuestSettingControl
                                                  "
                                                >
                                                  <div
                                                    class="col-md-8 col-xs-12"
                                                  >
                                                    <div
                                                      class="custom-control custom-radio custom-control-inline"
                                                      
                                                    >
                                                      <input
                                                        type="radio"
                                                        id="paymentMethod4"
                                                        name="paymentMethod"
                                                        class="custom-control-input"
                                                        v-model="
                                                          paymentFormData.payment_type
                                                        "
                                                        v-b-modal.boubyanModal
                                                        value="boubyan"
                                                      />
                                                      <label
                                                        class="custom-control-label"
                                                        for="paymentMethod4"
                                                      >
                                                        {{
                                                          $t(
                                                            "payment.boubyanOnlinePayment"
                                                          )
                                                        }}
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="col-md-4 col-xs-12"
                                                  >
                                                    <div class="online-media">
                                                      <img
                                                        src="@/assets/images/cart.png"
                                                        alt=""
                                                        srcset=""
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <!-- if error in choose payment method or not choosed  -->
                                              <div
                                                class="error text-center"
                                                v-for="(
                                                  error, index
                                                ) in errors.payment_type"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>

                                              <!-- terms and conditions  -->

                                              <b-form-checkbox
                                                v-model="
                                                  paymentFormData.accept_terms
                                                "
                                                class="terms my-4 d-inline-block custom-input"
                                              >
                                                <span class="text-dark">
                                                  {{ $t("payment.accept") }}
                                                </span>
                                              </b-form-checkbox>

                                              <a
                                                v-b-modal.terms&condation
                                                @click="
                                                  $bvModal.show('modal-scoped')
                                                "
                                                class="text-decoration-underline text-dark"
                                              >
                                                {{
                                                  $t(
                                                    "payment.termsAndConditions"
                                                  )
                                                }}</a
                                              >
                                              <b-modal
                                                size="lg"
                                                id="modal-scoped"
                                                :title="condations.title"
                                              >
                                                <p
                                                  v-html="
                                                    condations.description
                                                  "
                                                ></p>
                                                <template
                                                  #modal-footer="{ ok }"
                                                >
                                                  <b-button
                                                    size="sm"
                                                    variant="outline-success"
                                                    @click="
                                                      ok();
                                                      acceptMyTerms();
                                                    "
                                                  >
                                                    <h6 class="m-0">
                                                      <span class="mx-1">{{
                                                        $t("payment.accept")
                                                      }}</span>
                                                      <span class="mx-1">{{
                                                        $t(
                                                          "payment.termsAndConditions"
                                                        )
                                                      }}</span>
                                                    </h6>
                                                  </b-button>
                                                </template>
                                              </b-modal>
                                              <sup>*</sup>

                                              <!-- if terms and conditions not selected  -->

                                              <div
                                                class="error text-center"
                                                v-for="(
                                                  error, index
                                                ) in errors.accept_terms"
                                                :key="index"
                                              >
                                                {{ error }}
                                              </div>
                                              <!-- checkout button if user exist  -->
                                              <div class="checkout">
                                                <div
                                                  class="submit"
                                                  v-if="buyerUserData"
                                                >
                                                  <b-button
                                                    type="submit"
                                                    class="login-button bg-main"
                                                    disabled
                                                    v-if="checkoutSubmitted"
                                                  >
                                                    {{ $t("payment.checkout") }}
                                                    ...
                                                    <span>
                                                      <b-spinner
                                                        label="Spinning"
                                                        small
                                                      ></b-spinner>
                                                    </span>
                                                  </b-button>

                                                  <b-button
                                                    type="submit"
                                                    class="login-button bg-main"
                                                    @click="payment"
                                                    :disabled="
                                                      paymentFormData.payment_type ===
                                                        'bank' &&
                                                      paymentFormData.file ==
                                                        null
                                                    "
                                                    v-else
                                                  >
                                                    {{ $t("payment.checkout") }}
                                                  </b-button>
                                                </div>
                                                <!-- checkout button if user not exist (guest)  -->
                                                <div class="submit" v-else>
                                                  <b-button
                                                    type="submit"
                                                    class="login-button bg-main"
                                                    disabled
                                                    v-if="checkoutSubmitted"
                                                  >
                                                    {{ $t("payment.checkout") }}
                                                    ...
                                                    <span>
                                                      <b-spinner
                                                        label="Spinning"
                                                        small
                                                      ></b-spinner>
                                                    </span>
                                                  </b-button>

                                                  <b-button
                                                    type="submit"
                                                    class="login-button bg-main"
                                                    @click="guestPayment"
                                                    v-else
                                                  >
                                                    {{ $t("payment.checkout") }}
                                                  </b-button>
                                                </div>

                                                <!-- login modal if want to login if guest  -->

                                                <transition name="modal">
                                                  <div
                                                    class="modal-mask"
                                                    v-if="showModal"
                                                  >
                                                    <login-modal
                                                      @close="closeModal"
                                                    />
                                                  </div>
                                                </transition>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- this modal apper when bank payment method checked  -->
                              <b-modal
                                id="bankModal"
                                :title="$t('payment.uploadImage')"
                              >
                                <form
                                  class="bankData mb-5"
                                  @submit.prevent="checkoutbankUpload"
                                >
                                  <div class="d-flex">
                                    <p><b>IBan : </b></p>
                                    <p
                                      class="iban"
                                      v-if="companyIban"
                                      v-html="companyIban.description"
                                    ></p>
                                  </div>
                                  <div class="form-input mb-4">
                                    <label for="bankImage">
                                      {{ $t("payment.uploadImage") }}
                                    </label>
                                    <b-form-group>
                                      <b-form-file
                                        size="lg"
                                        id="bankImage"
                                        @change="uploadBankImage"
                                        :placeholder="
                                          $t('profile.filePlaceHolder')
                                        "
                                        drop-placeholder="Drop file here..."
                                      >
                                      </b-form-file>
                                    </b-form-group>
                                  </div>
                                </form>
                              </b-modal>
                              <!-- this modal apper when bank payment method checked  -->
                              <b-modal
                                id="boubyanModal"
                                :title="$t('payment.boubyanOnlinePayment')"
                                
                              >
                                <div class="visa-form">
                                  <form @submit.prevent="submitForm">
                                    <div>
                                      <label for="card_number"
                                        >{{$t('payment.CardNumber')}}</label
                                      >
                                      <input
                                        type="text"
                                        id="card_number"
                                        v-model="card_number"
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        maxlength="16"
                                        required
                                      />
                                    </div>

                                    <div>
                                      <label for="card_expiry_month"
                                        >{{$t('payment.ExpiryMonth')}}</label
                                      >
                                      <input
                                        type="text"
                                        id="card_expiry_month"
                                        v-model="card_expiry_month"
                                        placeholder="MM"
                                        maxlength="2"
                                        required
                                      />
                                    </div>

                                    <div>
                                      <label for="card_expiry_year"
                                        >{{$t('payment.ExpiryYear')}}</label
                                      >
                                      <input
                                        type="text"
                                        id="card_expiry_year"
                                        v-model="card_expiry_year"
                                        placeholder="YYYY"
                                        maxlength="2"
                                        required
                                      />
                                    </div>

                                    <div>
                                      <label for="cvv">CVV</label>
                                      <input
                                        type="password"
                                        id="cvv"
                                        v-model="cvv"
                                        placeholder="XXX"
                                        maxlength="3"
                                        required
                                      />
                                    </div>
                                    <!-- <div class="d-flex justify-content-end">

                                      <button type="submit" class="btn border-main p-2 my-3">{{$t('payment.validate')}}</button>
                                    </div> -->
                                  </form>
                                </div>
                              </b-modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- second : when page loading  -->

                <div
                  class="d-flex justify-content-center align-items-center flex-column"
                  v-else
                >
                  <img
                    src="@/assets/images/BeanLoading2.gif"
                    alt="cart-image"
                    class="w-25"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="step2 my-3">
        <div class="" v-if="!loading">
          <div class="">
            <!-- cart data according listed by suppliers  -->
            <div class="easy-trans col-12">
              <div class="cart">
                <!-- if data loading   -->
                <div
                  class="d-flex justify-content-center align-items-center flex-column"
                  v-if="loading"
                >
                  <img
                    src="@/assets/images/BeanLoading2.gif"
                    alt="cart-image"
                    class="w-25"
                  />
                </div>
                <!-- after loading  -->
                <div class="" v-else>
                  <div class="" v-if="cartItems !== null">
                    <!-- <h5 class="heading py-5 text-center">
                      {{ $t("cart.purchaseCart") }}
                    </h5> -->
                    <div
                      class="cart-table p-4"
                      v-for="(supplier, index) in cartItems"
                      :key="index"
                    >
                      <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                          <b-card-header
                            header-tag="header"
                            class="p-3 pb-0 d-flex justify-content-between align-items-center"
                            role="tab"
                            @click="rotateElement(`accordion-${index}`)"
                          >
                            <h5
                              class="name mb-0"
                              v-b-toggle="`accordion-${index}`"
                            >
                              <ins>{{ supplier.supplier_name }}</ins>
                            </h5>
                            <div class="icon">
                              <font-awesome-icon
                                icon="fa-solid fa-angle-down"
                              />
                            </div>
                          </b-card-header>
                          <b-collapse
                            :id="`accordion-${index}`"
                            visible
                            accordion="my-accordion"
                            role="tabpanel"
                          >
                            <b-card-body>
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>{{ $t("profile.productImage") }}</th>
                                    <!-- <th>{{ $t("cart.product") }}</th> -->
                                    <th>{{ $t("profile.productName") }}</th>
                                    <!-- <th>{{ $t("items.supplier") }}</th> -->
                                    <th>{{ $t("cart.price") }}</th>
                                    <th>{{ $t("cart.quantity") }}</th>
                                    <th>{{ $t("cart.total") }}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <!-- list suppliers in cart data  -->
                                <tbody class="supplier">
                                  <!-- supplier_name  -->
                                  <!-- <h5 class="name">
                                  {{ supplier.supplier_name }}
                                </h5> -->
                                  <!-- list products by this supplier -->
                                  <tr
                                    class="item-content"
                                    v-for="(
                                      item, index
                                    ) in supplier.products.filter(
                                      (item) => !item.basket_promotion_id
                                    )"
                                    :key="index"
                                  >
                                    <!-- product image and go to pproduct page with click  -->
                                    <td class="media">
                                      <router-link
                                        :to="{
                                          path: '/details',
                                          query: {
                                            id: `${item.product_supplier_id}`,
                                          },
                                        }"
                                        class="thumb time-holder"
                                      >
                                        <img
                                          :src="item.product_image"
                                          :alt="item.product_name + ' image'"
                                          class="product-image"
                                          v-if="item.product_image"
                                        />
                                        <img
                                          :src="logoEnv"
                                          v-else-if="
                                            !item.product_image && logoEnv
                                          "
                                          class="product-img"
                                          alt="logo"
                                        />
                                        <img
                                          src="@/assets/images/logo.png"
                                          v-else
                                          class="img-fluid"
                                          alt="logo"
                                          @click="goToHome()"
                                        />
                                        <br />
                                        <span
                                          v-if="
                                            item.buy_get_promotion_running_by_type
                                          "
                                          class="mx-2 mt-2"
                                        >
                                          <b class="time-count">
                                            {{ $t("profile.buy") }}
                                            {{
                                              item
                                                .buy_get_promotion_running_by_type
                                                .promotion.buy_x
                                            }}
                                            {{ $t("profile.get") }}
                                            {{
                                              item
                                                .buy_get_promotion_running_by_type
                                                .promotion.get_y
                                            }}
                                          </b>
                                        </span>
                                        <span
                                          v-if="item.gift_promotion_id"
                                          class="mx-2 mt-2"
                                        >
                                          <b class="time-count">
                                            {{ $t("profile.gift") }}
                                          </b>
                                        </span>
                                      </router-link>
                                    </td>
                                    <!-- product name  and go to pproduct page with click  -->
                                    <td>
                                      <router-link
                                        :to="{
                                          path: '/details',
                                          query: {
                                            id: `${item.product_supplier_id}`,
                                          },
                                        }"
                                      >
                                        {{ item.product_name }}
                                      </router-link>
                                    </td>
                                    <!-- <td v-if="supplier.supplier_name">
                                    {{supplier.supplier_name}}
                                  </td> -->
                                    <!-- if product price exist -->
                                    <td v-if="item.price">
                                      {{ item.price | fixedCurrency }}
                                      {{ currency }}
                                    </td>
                                    <!-- if product price not exist -->
                                    <td v-else>-</td>
                                    <!-- counter to update product quantity -->
                                    <td>
                                      <Counter
                                        :minimum="
                                          item.min_order_quantity
                                            ? item.min_order_quantity
                                            : 1
                                        "
                                        :quantity="item.quantity"
                                        v-if="!item.gift_promotion_id"
                                        :product="item"
                                        class="justify-content-center"
                                        @changeTitle="ChangeQ($event)"
                                      ></Counter>
                                      <span v-else>{{ item.quantity }}</span>
                                    </td>
                                    <!-- product price * product quantity = total product price -->
                                    <td v-if="item.product_sub_total">
                                      {{
                                        item.product_sub_total | fixedCurrency
                                      }}
                                      {{ currency }}
                                    </td>
                                    <td v-else>-</td>

                                    <!-- remove product from cart -->

                                    <td>
                                      <div
                                        class="actions"
                                        @click="removeFromCart(item)"
                                        v-if="!item.gift_promotion_id"
                                      >
                                        <span class="action-icon">
                                          <font-awesome-icon
                                            icon="fa-solid fa-xmark"
                                            size="xl"
                                          />
                                        </span>
                                      </div>
                                      <div class="" v-else>
                                        <img
                                          src="@/assets/images/giftbox.png"
                                          class="gift-product"
                                          alt="gift-product"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    class="item-content"
                                    v-for="(
                                      item, index
                                    ) in supplier.products.filter(
                                      (item) => item.basket_promotion_id
                                    )"
                                    :key="index"
                                  >
                                    <!-- product image and go to pproduct page with click  -->
                                    <td class="media">
                                      <router-link
                                        :to="{
                                          path: '/basketOfferDetails',
                                          query: {
                                            id: `${item.basket_promotion_id}`,
                                          },
                                        }"
                                        class="thumb time-holder"
                                      >
                                        <img
                                          :src="item.basket_image"
                                          :alt="item.basket_name + ' image'"
                                          class="product-image"
                                        />
                                        <span
                                          v-if="item.basket_promotion_id"
                                          class="mt-2"
                                        >
                                          <b class="time-count">
                                            {{ $t("profile.basketDeal") }}
                                          </b>
                                        </span>
                                      </router-link>
                                    </td>
                                    <!-- product name  and go to pproduct page with click  -->
                                    <td>
                                      <router-link
                                        :to="{
                                          path: '/basketOfferDetails',
                                          query: {
                                            id: `${item.basket_promotion_id}`,
                                          },
                                        }"
                                      >
                                        {{ item.basket_name }}
                                      </router-link>
                                    </td>
                                    <!-- if product price exist -->
                                    <td v-if="item.price || item.price >= 0">
                                      {{ item.price | fixedCurrency }}
                                      {{ currency }}
                                    </td>
                                    <!-- if product price not exist -->
                                    <td v-else>-</td>
                                    <!-- counter to update product quantity -->
                                    <td>
                                      <BasketCounter
                                        :minimum="1"
                                        :quantity="item.quantity"
                                        :product="item"
                                        class="justify-content-center"
                                        @changeTitle="ChangebasketQ($event)"
                                      >
                                      </BasketCounter>
                                    </td>
                                    <!-- product price * product quantity = total product price -->
                                    <td
                                      v-if="
                                        item.product_sub_total ||
                                        item.product_sub_total >= 0
                                      "
                                    >
                                      {{
                                        item.product_sub_total | fixedCurrency
                                      }}
                                      {{ currency }}
                                    </td>
                                    <td v-else>-</td>

                                    <!-- remove product from cart -->

                                    <td>
                                      <div
                                        class="actions"
                                        @click="removebasketFromCart(item)"
                                      >
                                        <span class="action-icon">
                                          <font-awesome-icon
                                            icon="fa-solid fa-xmark"
                                            size="xl"
                                          />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <!-- select shipping or pick-up  -->
                                    <td colspan="12" class="p-0 mt-0">
                                      <div class="order-shipping">
                                        <div :class="$i18n.locale">
                                          <form
                                            @change="
                                              orderType(supplier.supplier_id)
                                            "
                                            class="d-flex align-items-start justify-content-start px-2 results-form flex-column"
                                          >
                                            <!-- if select shipping  -->
                                            <p class="h5">
                                              {{
                                                $t(
                                                  "cart.choose_your_delivery_method"
                                                )
                                              }}:
                                            </p>
                                            <label
                                              @click="shippingStore(supplier)"
                                              class="shipping-label mt-2"
                                            >
                                              <input
                                                @change="changeShipping($event)"
                                                @input="shippingStore(supplier)"
                                                type="radio"
                                                value="0"
                                                :name="'types-' + index"
                                                v-model="
                                                  ratingNum[index].delivery_type
                                                "
                                                class="checkFirst"
                                                id="check"
                                              />
                                              <!-- <span class="mx-2 ml-0"
                                                >{{ $t("payment.delivery") }} 
                                                <b class="mx-2"
                                                  ><b class="main-color"
                                                    >2.00 kwd</b
                                                  >
                                                  next day delivery
                                                </b>
                                                </span
                                              > -->
                                            </label>
                                            <!-- if select pickup  -->
                                            <label class="shipping-label mt-2">
                                              <input
                                                @input="
                                                  changePickup($event, supplier)
                                                "
                                                @click="
                                                  changePickup($event, supplier)
                                                "
                                                type="radio"
                                                value="1"
                                                :name="'types-' + index"
                                                v-model="
                                                  ratingNum[index].delivery_type
                                                "
                                              />
                                              <span class="mx-2">
                                                {{ $t("payment.pickup") }} :
                                                <b class="main-color mx-2"
                                                  >Pick-up location</b
                                                >
                                              </span>
                                              <button
                                                class="outline-main"
                                                v-if="
                                                  ratingNum[index]
                                                    .delivery_type == 1 ||
                                                  ratingNum[index]
                                                    .delivery_type == '1'
                                                "
                                                @click.prevent="
                                                  ratingNum[
                                                    index
                                                  ].delivery_type = 0;
                                                  resetPickup();
                                                  getLoggedFirstShippingFees();
                                                "
                                              >
                                                x
                                              </button>
                                            </label>
                                            <!-- if supplier has address in pickup  -->
                                            <b-form-select
                                              v-model="
                                                ratingNum[index]
                                                  .supplier_address_id
                                              "
                                              @input="selectAddressUUID"
                                              @change="
                                                selectType(supplier, index)
                                              "
                                              class="w-100 mt-2 supplierAddresses d-none"
                                              :class="{
                                                'text-danger':
                                                  ratingNum[index]
                                                    .supplier_address_id ===
                                                  null,
                                                'text-dark d-block':
                                                  ratingNum[index]
                                                    .supplier_address_id !==
                                                  null,
                                              }"
                                            >
                                              <b-form-select-option
                                                selected
                                                disabled
                                                value="null"
                                                ><span>{{
                                                  $t("cart.selectPickupAddress")
                                                }}</span></b-form-select-option
                                              >
                                              <b-form-select-option
                                                v-for="(
                                                  address, index
                                                ) in supplier.supplier_addresses"
                                                :key="index"
                                                :value="address"
                                                >{{ address.country.title }} ,
                                                {{ address.region.title }} ,
                                                {{ address.city.title }}
                                              </b-form-select-option>
                                            </b-form-select>
                                            <!-- print result of picked address cost price  -->
                                            <span
                                              class="feedsResult m-0"
                                            ></span>
                                            <h4 class="pickupNoData"></h4>
                                            <!-- <br /> -->

                                            <!-- list the available addresses to pickup for this supplier  -->
                                            <ul
                                              class="list-unstyled m-0 px-3"
                                              v-if="
                                                firstFees || deliverType == true
                                              "
                                            >
                                              <li
                                                v-for="(
                                                  fee, index
                                                ) in firstFees"
                                                :key="index"
                                              >
                                                <h5
                                                  v-if="
                                                    index ==
                                                    supplier.supplier_id
                                                  "
                                                  class="feedsResultShipping mb-0"
                                                  :value="fee.shipping_fee"
                                                >
                                                  <span>{{
                                                    $t("profile.deleiveryFees")
                                                  }}</span>
                                                  <span>{{
                                                    fee.shipping_fee
                                                      | fixedCurrency
                                                  }}</span>
                                                  <span>{{ currency }}</span>
                                                  <p
                                                    v-if="fee.message"
                                                    class="message"
                                                  >
                                                    {{ fee.message }}
                                                  </p>
                                                </h5>
                                              </li>
                                            </ul>
                                          </form>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center align-items-center flex-column"
                    v-else
                  >
                    <img
                      src="@/assets/images/BeanLoading2.gif"
                      alt="cart-image"
                      class="w-25"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- second : when page loading  -->

        <div
          class="d-flex justify-content-center align-items-center flex-column"
          v-else
        >
          <img
            src="@/assets/images/BeanLoading2.gif"
            alt="cart-image"
            class="w-25"
          />
        </div>
      </div>
    </div>

    <!-- last : when no data  -->
    <div
      class="d-flex justify-content-center align-items-center py-5 my-5 text-center"
      v-else-if="!hasProducts"
    >
      <div>
        <img
          src="@/assets/images/pngfind.com-cart-png-2727925.png"
          alt="no-data-in-cart"
          srcset=""
        />
        <h4 class="mt-3">
          {{ $t("cart.noCartProducts") }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
// cart component that contains all cart data
import Counter from "@/components/global/Counter.vue";
import BasketCounter from "@/components/global/BasketCounter.vue";
import suppliers from "@/services/suppliers";
import globalAxios from "@/services/global-axios";
import LoginModal from "@/components/global/loginModal.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import auth from "@/services/auth";
import profile from "@/services/profile";
import Vue from "vue";
import dynamicComponent from "@/components/global/dynamicComponent";
import { createdFormData } from "@/services/helpers.js";

export default {
  components: {
    Counter,
    BasketCounter,
    LoginModal,
    dynamicComponent,
    VueSlickCarousel,
  },
  data() {
    return {
      // slider settings
      paymentMethods: {
        bankTransfer: true,
        cashOnDelivery: true,
        onlinePayment: true,
      },
      settings: {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,
        centerMode: false,
        clickable: true,
        accessibility: true,
        draggable: true,
        focusOnSelect: true,

        responsive: [
          {
            breakpoint: 1191,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 820,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      coupon: null,
      discount: 0,
      loading: false,
      productLoading: false,
      freeDelivery: false,
      total_cart: [],
      newCartData: null,
      selectedCoupon: null,
      buttonDisabled: false,
      cartItems: null,
      price_after_discount: null,
      couponChecked: false,
      selectedInput: null,
      selectedButton: null,
      selectedSpan: null,
      myQuantity: null,
      showModal: false,
      priceData: null,

      ratingNum: [],
      deliverType: true,
      selectAddressShape: null,

      /**
       * @vuese
       *  if logged in
       */

      form: {
        country_id: null,
        region_id: null,
        city_id: null,
        building_number: null,
        floor: null,
        block: null,
        pin_code: "",
        notes: null,
        address_line_1: null,
        avenue: null,
      },

      /**
       * @vuese
       *  if not logged in
       */
      newForm: {
        country_id: null,
        region_id: null,
        city_id: null,
        building_number: null,
        apartment: null,
        pin_code: null,
        notes: null,
        address_line_1: null,
        floor: null,
        // new adds
        name: null,
        street: null,
        block: null,
        avenue: null,
      },
      countries: [],
      cities: [],
      regions: [],
      errors: {},
      newAddressOption: null,
      selectedAddress: null,
      addresses: null,
      submitted: false,
      supplierAddress: null,
      suppier_id: null,
      selectedInputText: "",
      firstFees: null,
      showBtnClicked: true,
      totalDiscount: null,
      cart_sub_total: null,
      totalPayment: null,
      /**
       * @vuese
       *  payment
       */
      count: 0,
      storedData: null,

      /**
       * @vuese
       *  collect all checkout data here
       */
      paymentFormData: {
        comment: null,
        phone: null,
        payment_type: null,
        first_name: null,
        last_name: null,

        country: "",
        governorate: "",
        city: "",
        email: null,
        address_uuid: null,
        suppliers: null,
        redirect_url: null,
        country_code: null,
        accept_terms: false,
        company_name: null,
        coupons: [],
        file: null,
      },
      paymentCountries: [],
      paymentCities: [],
      paymentRegions: [],
      condations: {},

      availablePickup: true,
      expanded: true,
      closeBtn: true,
      shippingCartFee: null,
      totalFees: null,

      totalPaymentReplacement: null,
      totalDiscountReplacement: null,
      localStoreFail: false,
      hasProducts: false,
      walletData: null,
      pin_codeMaxLength: 6,
      postalError: false,
      localClicked: false,
      couponRemoved: false,
      checkoutSubmitted: false,
      couponText: null,
      coupons: [],
      existCoupons: [],
      couponError: null,
      selectedPhonePrefix: null,
      en_formNames: ["Office", "Home"],
      ar_formNames: ["المنزل", "المكتب"],
      en_B2B_formNames: ["Head office", "Ware house", "Retail shop"],
      ar_B2B_formNames: ["مدير المكتب", "مستودع", "محل بيع بالتجزئه"],
      companyIban: null,
      validCoupon: false,
      cachMethodAvailable: false,
      visaMethodAvailable: false,
      walletMethodAvailable: false,
      walletVisaMethodAvailable: false,
      bankMethodAvailable: false,
      couponMethodAvailable: false,
      dynamicInputs: null,
      // boubyan visa modal data 
      card_number: null,
      card_expiry_month: null,
      card_expiry_year: null,
      cvv: null,
    };
  },
  mounted() {
    this.getCartProducts();

    this.getAllCountires();
    if (this.buyerUserData) {
      this.getAllAdresses();
    }
    localStorage.removeItem("s_id");
    localStorage.removeItem("cou");

    /**
     * @vuese
     * setting user or guest country from stored country
     */

    let selectedCountry = localStorage.getItem("country");
    if (this.buyerUserData) {
      this.paymentFormData.country = this.buyerUserData.country_id;
      this.form.country_id = this.buyerUserData.country_id;
      this.getAllRegions();
    } else if (!this.buyerUserData && selectedCountry) {
      this.paymentFormData.country = JSON.parse(selectedCountry).id;
      this.form.country_id = JSON.parse(selectedCountry).id;
      this.getAllRegions();
    }

    /**
     * @vuese
     * start setting user form data from data comes from backend
     */

    this.paymentFormData.governorate = this.buyerUserData
      ? this.buyerUserData.region_id
      : "";
    this.paymentFormData.city = this.buyerUserData
      ? this.buyerUserData.city_id
      : "";

    this.paymentFormData.suppliers = this.mySuppliers.suppliers;
    this.paymentFormData.address_uuid = localStorage.getItem(
      "globalAddressUUID"
    )
      ? localStorage.getItem("globalAddressUUID")
      : "";

    this.paymentFormData.postal_code = this.buyerUserData
      ? this.buyerUserData.pin_code
      : null;
    this.paymentFormData.first_name = this.buyerUserData
      ? this.buyerUserData.first_name
      : "";
    this.paymentFormData.last_name = this.buyerUserData
      ? this.buyerUserData.last_name
      : "";

    this.paymentFormData.company_name = this.buyerUserData
      ? this.buyerUserData.company_name
      : "";

    this.paymentFormData.phone = this.buyerUserData
      ? this.buyerUserData.mobile_number.replace("+20", "").replace("+965", "")
      : "";

    this.selectedPhonePrefix = JSON.parse(localStorage.getItem("country"));

    // this.paymentFormData.country_code = this.buyerUserData
    //   ? this.buyerUserData.phone_prefix
    //   : this.selectedPhonePrefix.phone_prefix;

    // this.paymentFormData.country_code = this.buyerUserData.country_id;
    this.paymentFormData.country_code = JSON.parse(
      this.selectedCountry
    ).phone_prefix;

    this.paymentFormData.email = this.buyerUserData
      ? this.buyerUserData.email
      : "";
    // this.paymentFormData.coupons = this.existCoupons ? this.existCoupons : "";

    const backUrl = `${this.mainDoamin}complete-checkout`;
    this.paymentFormData.redirect_url = backUrl;

    /**
     * @vuese
     *   end setting user form data from data comes from backend
     */

    this.getTerms();
    /**
     * @vuese
     *   checkAddressesFormControl
     */

    this.checkAddressesForm();

    /**
     * @vuese
     *   if user exist get wallet data
     */

    if (this.buyerUserData) {
      this.getWallet();
    }

    /**
     * @vuese
     *   get address uuid form stored data if exist
     */

    let addressUUID = localStorage.getItem("globalAddressUUID");

    /**
     * @vuese
     *   address uuid not exist setting the user uuid from user backend data
     */

    if (
      (this.buyerUserData && addressUUID == undefined) ||
      (this.buyerUserData && addressUUID == "undefined")
    ) {
      localStorage.setItem(
        "globalAddressUUID",
        this.buyerUserData.address_uuid
      );
    }

    this.getCompanyIban();
    this.checPaymentAvailableTypes();
    this.checkDynamicInputs();

    this.checkPaymentMethods();
  },
  methods: {
    checkPaymentMethods() {
      profile
        .getPaymentsMethods()
        .then((res) => {
          res.data.items.forEach((item) => {
            if (item.api_name == "bank_transfer_payment") {
              this.paymentMethods.bankTransfer = item.status;
            } else if (item.api_name == "cash_on_delivery_payment") {
              this.paymentMethods.cashOnDelivery = item.status;
            } else if (item.api_name == "online_payment") {
              this.paymentMethods.onlinePayment = item.status;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * @vuese
     *   check Dynamic Inputs
     */
    checkDynamicInputs() {
      auth.dynamicInputs("user-address").then((res) => {
        this.dynamicInputs = res.data.items;
        this.dynamicInputs.map((input) => {
          this.form[input.uuid] = null;
          if (input.type == "checkbox") {
            this.form[input.uuid] = false;
          }
        });
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    formatPin_code(e) {
      return String(e).substring(0, 6);
    },

    /**
     * @vuese
     *   when change coupon
     */

    changeCoupon($event) {
      this.selectedCoupon = $event.target.value;

      let input = $event.target;

      this.selectedInput = input;

      let button = input.parentElement.previousElementSibling;

      this.selectedButton = button;

      let span = input.nextElementSibling;

      this.selectedSpan = span;
    },

    /**
     * @vuese
     *   get cart data from backend
     */

    getCartProducts() {
      globalAxios
        .post(`cart`)
        .then((res) => {
          if (res.data.items.length >= 0) {
            this.hasProducts = false;
          } else if (res.data.items.length) {
            this.hasProducts = true;
          }
          if (res.data.items.cart_items.length > 0) {
            this.hasProducts = true;
          }

          this.cartItems = res.data.items.cart_items.map((cartItem) => {
            return {
              ...cartItem,
              supplier_addresses: [],
            };
          });
          // setting cart data and cheeckout coasts data

          this.priceData = res.data.items;
          this.cart_sub_total = res.data.items.cart_sub_total;
          this.totalDiscount = res.data.items.cart_sub_total_disc.toFixed(3);
          this.totalPayment = res.data.items.cart_sub_total_after_disc;
          this.shippingCartFee = res.data.items.cart_total_shipping_fee;

          this.totalPaymentReplacement = this.totalPayment;
          this.totalDiscountReplacement = this.totalDiscount;
          this.ratingNum = res.data.items.cart_items.map(() => {
            return {
              delivery_type: "0",
              supplier_address_id: null,
            };
          });
        })
        .then(() => {
          if (this.buyerUserData) {
            // get shipping coast data
            let address_uuid = this.buyerUserData.address_uuid;
            this.getLoggedFirstShippingFees(address_uuid);
          }
        })
        .finally(() => {
          setTimeout(() => {
            // select all shipping inputs to check them and get coasts

            var checkboxes = document.getElementsByClassName("checkFirst");
            for (let index = 0; index < checkboxes.length; index++) {
              const element = checkboxes[index];
              element.parentElement.click();
            }

            var existingAddresses =
              document.querySelector(".existingAddresses");
            for (var i = 0; i < checkboxes.length; i++) {
              if (existingAddresses) {
                existingAddresses.click();
                existingAddresses.checked = true;
              }
            }
          }, 500);

          if (localStorage.getItem("buyerUserData") === null) {
            if (document.querySelector(".GuestNewAddress") !== null) {
              document.querySelector(".GuestNewAddress").click();
            }
            setTimeout(() => {
              document.querySelector(".GuestNewAddress").click();
            }, 500);
          }
        });
    },

    /**
     * @vuese
     *   remove product from cart
     */
    removeFromCart(product) {
      this.$store.dispatch("cart/removeProductFromCart", {
        product: product,
      });
      this.cartItems = null;
      setTimeout(() => {
        this.getCartProducts();
        this.paymentFormData.coupons = [];
        this.existCoupons = [];
        this.coupons = [];
        this.$store.dispatch("cart/getCartProducts");
      }, 1000);
    },
    /**
     * @vuese
     *   remove basket product from cart
     */
    removebasketFromCart(product) {
      this.$store.dispatch("cart/removeProductFromCart", {
        product: product,
        basket_promotion_id: product.basket_promotion_id,
      });
      this.cartItems = null;
      setTimeout(() => {
        this.getCartProducts();
        this.paymentFormData.coupons = [];
        this.existCoupons = [];
        this.coupons = [];
        this.$store.dispatch("cart/getCartProducts");
      }, 1000);
    },

    /**
     * @vuese
     *  this fnction user when using the old coupon style to remove disaple input
     */

    removeDisabled() {
      let myInput = this.selectedInput;

      myInput.removeAttribute("disabled");
      myInput.value = "";
      let button = this.selectedButton;
      button.removeAttribute("disabled");

      this.couponChecked = false;
      globalAxios.post(`cart`).then((res) => {
        this.total_cart = res.data.items;
      });

      this.selectedSpan.style.display = "none";
      document.querySelector(".itemInput").removeAttribute("disabled");
      document.querySelector(".login-button").removeAttribute("disabled");

      this.totalDiscountReplacement = parseFloat(0).toFixed(3);
      if (this.totalDiscountReplacement == 0) {
        this.totalPaymentReplacement = this.totalPayment;
      }
      document.querySelector(".couponValid").innerHTML = "";
      document.querySelector(".couponNotValid").innerHTML = "";

      let enteredCoupons = document.getElementsByClassName("couponNotValid");
      if (enteredCoupons) {
        for (let index = 0; index < enteredCoupons.length; index++) {
          const element = enteredCoupons[index];
          element.innerHTML = "";
        }
      }
    },

    /**
     * @vuese
     *   this fnction user when using the old coupon style to check coupon validity and dislay response and display data
     */

    checkCoupon(supplier) {
      var data = {
        coupons: [
          {
            coupon: this.selectedCoupon,
            supplier_id: supplier.supplier_id,
          },
        ],
      };

      suppliers
        .checkCoupon(data)
        .then((res) => {
          if (res.status == 200) {
            localStorage.setItem("cou", this.selectedCoupon);
            if (res.data.items.total_cart.total_discount !== 0) {
              this.selectedInput.parentElement.parentElement.parentElement.parentElement.querySelector(
                ".couponValid"
              ).innerHTML = `${this.$t("payment.couponValid")} `;
              this.selectedInput.parentElement.parentElement.parentElement.parentElement.querySelector(
                ".couponNotValid"
              ).innerHTML = ``;
            } else {
              this.selectedInput.parentElement.parentElement.parentElement.parentElement.querySelector(
                ".couponNotValid"
              ).innerHTML = `${this.$t("payment.couponNotValid")}`;
              this.selectedInput.parentElement.parentElement.parentElement.parentElement.querySelector(
                ".couponValid"
              ).innerHTML = ``;
            }
            this.totalDiscount =
              res.data.items.total_cart.total_discount.toFixed(3);

            this.total_cart = res.data.items;
            this.sucessMsg(res.data.message);
            this.couponChecked = true;

            let myInput = this.selectedInput;
            let button = this.selectedButton;

            let span = this.selectedSpan;

            span.style.display = "block";
            button.setAttribute("disabled", "true");

            myInput.setAttribute("disabled", "true");

            if (res.data.items.total_cart.total_discount == 0) {
              this.totalDiscountReplacement = parseFloat(this.totalDiscount);
              this.totalPaymentReplacement = parseFloat(
                this.totalDiscountReplacement
              );
            } else {
              this.totalDiscountReplacement =
                parseFloat(this.totalDiscountReplacement) +
                parseFloat(res.data.items.total_cart.total_discount).toFixed(3);
              this.totalPaymentReplacement =
                parseFloat(this.totalPaymentReplacement) -
                parseFloat(res.data.items.total_cart.total_discount);
            }
          }
        })
        .catch((error) => {
          if (error) {
            const err = Object.values(error)[2].data;
            this.errors = err.items;
            this.errMsg(err.message);
          }
        });
    },

    /**
     * @vuese
     *   login First if you want to add coupon if you'r a guest
     */

    loginFirst() {
      Vue.swal({
        title: this.$t("singleProduct.loginFirst"),
        text: this.$t("singleProduct.registerNow"),
        icon: "warning",
        // buttons: ["Oh noez!", true],
        dangerMode: true,
      }).then(() => {
        this.$router.push("/user-register");
      });
    },

    /**
     * @vuese
     *   change quantity of product that in table
     */
    ChangeQ(myQuantity) {
      if (myQuantity > 0) {
        this.myQuantity = myQuantity;
      }

      // this.cartItems = null;

      setTimeout(() => {
        this.getCartProducts();
        this.existCoupons = [];
        this.coupons = [];
        this.validCoupon = false;
      }, 100);
      setTimeout(() => {
        this.$store.dispatch("cart/getCartProducts");
      }, 300);
    },
    /**
     * @vuese
     *   change basket quantity  in table
     */
    ChangebasketQ(myQuantity) {
      if (myQuantity > 0) {
        this.myQuantity = myQuantity;
      }

      // this.cartItems = null;

      setTimeout(() => {
        this.getCartProducts();
        this.existCoupons = [];
        this.coupons = [];
        this.validCoupon = false;
      }, 100);
    },

    /**
     * @vuese
     *   close modal
     */

    closeModal() {
      this.showModal = false;
    },

    /**
     * @vuese
     *   open modal
     */
    openModal() {
      this.showModal = true;
    },
    resetPickup() {
      document.getElementsByClassName("feedsResult").innerHTML = "";
      this.selectedInput.parentElement.parentElement
        .querySelector(".supplierAddresses")
        .classList.remove("d-block");

      this.selectedInput.parentElement.parentElement
        .querySelector(".supplierAddresses")
        .classList.add("d-none");
    },

    /**
     * @vuese
     *   change selected address and display the result of new address
     */
    changeAddress() {
      document.getElementsByClassName("feedsResult").innerHTML = "";

      var doc = document.querySelectorAll(".feedsResult");

      for (var i = 0; i < doc.length; i++) {
        if (doc[i].classList.contains("d-block")) {
          doc[i].classList.remove("d-block");
          doc[i].innerHTML = "";
        }
      }

      this.newForm = this.selectedAddress;

      localStorage.setItem("globalAddressUUID", this.selectedAddress.uuid);

      setTimeout(() => {
        let address_uuid = localStorage.getItem("globalAddressUUID");
        suppliers
          .getFirstShippingFees(
            address_uuid,
            JSON.parse(this.selectedCountry).id
          )
          .then((res) => {
            this.firstFees = res.data.items;
            this.sucessMsg(res.data.message);

            let arr = res.data.items;
            var size = Object.values(arr);

            let myData = 0;
            for (let index = 0; index < size.length; index++) {
              const element = size[index].shipping_fee;

              myData += parseFloat(element);
            }

            this.totalPaymentReplacement = this.totalPayment;

            this.shippingCartFee = myData;

            this.totalPaymentReplacement += parseFloat(myData);

            if (myData == 0) {
              this.totalPaymentReplacement = this.totalPayment;
            }

            if (this.totalDiscountReplacement == parseFloat(0)) {
              this.totalPaymentReplacement -= parseFloat(
                this.totalDiscountReplacement
              );
            }
          })
          .catch((err) => {
            console.log(err);
            let error = Object.values(err)[2].data;
            this.errors = error.items;
            this.errMsg(error.message);
          });
      }, 200);
    },

    /**
     * @vuese
     *   select address uuid to pass it to ckeckout data and setting coasts data
     */
    selectAddressUUID(myselectAddressUUID) {
      this.supplierAddress = myselectAddressUUID.uuid;
      this.address_uuid = myselectAddressUUID.uuid;
      localStorage.setItem("addressUUID", myselectAddressUUID.uuid);

      let newFee = this.selectedInput.parentElement.parentElement
        .querySelector(".feedsResultShipping")
        .getAttribute("value");

      this.shippingCartFee -= parseFloat(newFee);
      this.totalPaymentReplacement -= parseFloat(newFee);
    },

    /**
     * @vuese
     *   select type (shipping or pickup ) and store it in store.js
     */
    selectType: function (supplier) {
      let newRating = {
        id: supplier.supplier_id,
        supplier_id: supplier.supplier_id,
        shipping_type: 1,
        coupon: supplier.coupon ? supplier.coupon : "",
        // point_of_sell_uuid: this.supplierAddress,
        point_of_sell_uuid:
          localStorage.getItem("addressUUID") !== null ||
          localStorage.getItem("addressUUID") !== undefined
            ? localStorage.getItem("addressUUID")
            : "",
      };
      this.$store.dispatch("suppliers/addSupplierToCart", {
        supplier: newRating,
      });
      this.checkSupplierFees(supplier);

      let myControler = this.$store.state.suppliers.suppliers;
      for (let index = 0; index < myControler.length; index++) {
        const element = myControler[index].supplier;

        if (element.shipping_type == 0 && element.id == supplier.supplier_id) {
          element.shipping_type = 1;
          element.point_of_sell_uuid =
            localStorage.getItem("addressUUID") !== null ||
            localStorage.getItem("addressUUID") !== undefined
              ? localStorage.getItem("addressUUID")
              : null;
        } else if (
          element.shipping_type == 1 &&
          element.id == supplier.supplier_id
        ) {
          element.shipping_type = 1;
          element.point_of_sell_uuid =
            localStorage.getItem("addressUUID") !== null ||
            localStorage.getItem("addressUUID") !== undefined
              ? localStorage.getItem("addressUUID")
              : null;
        }
      }
    },

    /**
     * @vuese
     *   address functions
     */

    getAllAdresses() {
      profile.getAllAdresses().then((res) => {
        this.addresses = res.data.items;
        for (let index = 0; index < res.data.items.length; index++) {
          const element = res.data.items[index];
          const element2 = element.is_default;
          if (element2) {
            this.selectedAddress = element;
            localStorage.setItem(
              "globalAddressUUID",
              this.selectedAddress.uuid
            );
          }
        }
        if (res.data.items.length == 0) {
          setTimeout(() => {
            let newInput = document.querySelector(".GuestNewAddress");
            if (newInput) {
              newInput.click();
            }
          }, 500);
        }
        if (this.buyerUserData) {
          setTimeout(() => {
            for (let index = 0; index < res.data.items.length; index++) {
              const element = res.data.items[index];
              // element.parentElement.click();

              // new edit

              if (element.parentElement) {
                element.parentElement.checked = true;
                element.parentElement.click();
              }

              // console.log(element);
            }

            var existingAddresses =
              document.querySelector(".existingAddresses");
            for (var i = 0; i < res.data.items.length; i++) {
              if (res.data.items.length > 0) {
                existingAddresses.click();
                existingAddresses.checked = true;
              }
            }
          }, 700);
        }

        setTimeout(() => {
          this.goTop();
        }, 500);
      });
    },

    /**
     * @vuese
     *   Countires
     */
    getAllCountires() {
      auth.getAllCountires().then((res) => {
        this.countries = res.data.items;
      });
    },

    /**
     * @vuese
     *   getAllRegions
     */
    getAllRegions() {
      profile.getAllRegions(this.form.country_id).then((res) => {
        this.regions = res.data.items;
        this.form.region_id = null;
        this.form.city_id = null;
      });
    },

    /**
     * @vuese
     *  Cities
     */
    getAllCities() {
      profile.getAllCities(this.form.region_id).then((res) => {
        this.cities = res.data.items;
        this.form.city_id = null;
      });
    },

    /**
     * // order-shipping page functions
     */

    /**
     * @vuese
     *  checkAddressesForm
     */
    checkAddressesForm() {
      profile.checkAddressesForm().then((res) => {
        let formControl = res.data.items;
        formControl.map((element) => {
          if (element.status !== 1) {
            this.form[element.input_key] = undefined;
          } else {
            this.form[element.input_key] = null;
          }
          if (element.input_key == "country_id" && element.status == 1) {
            /**
             * @vuese
             * setting user or guest country from stored country
             */

            let selectedCountry = localStorage.getItem("country");
            if (this.buyerUserData) {
              this.paymentFormData.country = this.buyerUserData.country_id;
              this.form.country_id = this.buyerUserData.country_id;
            } else if (!this.buyerUserData && selectedCountry) {
              this.paymentFormData.country = JSON.parse(selectedCountry).id;
              this.form.country_id = JSON.parse(selectedCountry).id;
            }
          }
          if (element.input_key == "region_id" && element.status !== 1) {
            this.form.region_id = undefined;
          }

          if (element.input_key == "city_id" && element.status !== 1) {
            this.form.city_id = undefined;
          }
        });
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },

    /**
     * @vuese
     *  createAdress
     */

    createAdress() {
      let address_uuid = localStorage.getItem("globalAddressUUID");
      (this.form.is_sale_point = false),
        profile
          .createAdress(createdFormData(this.form))
          .then((res) => {
            this.sucessMsg(res.data.message);
            this.errors = {};
            this.getAllAdresses();
            this.showForm = false;
            this.form = {};
            this.checkAddressesForm();
            if (res.status == 200) {
              this.submitted = true;

              setTimeout(() => {
                this.getShippingFeesExist(address_uuid);
                this.expanded = false;
              }, 500);
            }
            localStorage.setItem("globalAddressUUID", res.data.items.uuid);
            setTimeout(() => {
              let address_uuid = localStorage.getItem("globalAddressUUID");
              this.getLoggedFirstShippingFees(address_uuid);
            }, 500);
          })
          .then(() => {
            setTimeout(() => {
              if (this.addresses[this.addresses.length - 1]) {
                this.selectedAddress =
                  this.addresses[this.addresses.length - 1];
                localStorage.setItem(
                  "globalAddressUUID",
                  this.selectedAddress.uuid
                );
              }
              var existingAddresses =
                document.querySelector(".existingAddresses");

              existingAddresses.click();
              existingAddresses.checked = true;
              this.expanded = false;
            }, 1000);
          })
          .catch((error) => {
            const err = Object.values(error)[2].data;
            this.errors = err.items;
            this.errMsg(err.message);
          });
    },
    /**
     * @vuese
     *  store address if guest
     */

    localStoreAdresses() {
      this.localClicked = true;

      if (
        this.form.country_id !== null &&
        this.form.region_id !== null &&
        this.form.city_id !== null &&
        this.form.address_line_1 !== null &&
        this.form.country_id !== "" &&
        this.form.region_id !== "" &&
        this.form.city_id !== "" &&
        this.form.address_line_1 !== ""
      ) {
        this.sucessMsg(this.$t("cart.success"));
        this.submitted = true;
        setTimeout(() => {
          this.expanded = false;
        }, 500);
        this.postalError = false;

        this.getGuestFirstShippingFees();

        this.localStoreFail = true;
      } else if (
        this.form?.pin_code?.length < 6 &&
        this.form?.pin_code !== ""
      ) {
        this.postalError = true;
      } else {
        this.postalError = false;
        this.errMsg(this.$t("cart.fillData"));
        this.localStoreFail = false;
      }
    },
    /**
     * @vuese
     *   supplier addresses pickup addresses
     */
    getSupplierAddress(supplierId) {
      suppliers.getSupplierAddress(supplierId).then((res) => {
        this.cartItems.forEach((item, index) => {
          if (item.supplier_id == supplierId)
            this.cartItems[index].supplier_addresses = res.data.items;
        });
        if (res.data.items.length == 0 || res.data.items == "") {
          this.availablePickup = false;

          this.selectedInput.parentElement.parentElement
            .querySelector(".supplierAddresses")
            .classList.remove("d-block");

          this.selectedInput.parentElement.parentElement
            .querySelector(".supplierAddresses")
            .classList.add("d-none");

          this.selectedInput.parentElement.parentElement
            .querySelector(".feedsResult")
            .classList.remove("d-block");

          this.selectedInput.parentElement.parentElement
            .querySelector(".feedsResult")
            .classList.add("d-none");

          this.selectedInput.parentElement.parentElement
            .querySelector(".pickupNoData")
            .classList.remove("d-none");
          this.selectedInput.parentElement.parentElement
            .querySelector(".pickupNoData")
            .classList.add("d-block");

          this.selectedInput.parentElement.parentElement.querySelector(
            ".pickupNoData"
          ).innerHTML = `${this.$t("profile.noPickupData")}`;

          if (
            this.selectedInput.parentElement.parentElement.querySelector(
              ".feedsResultShipping"
            )
          ) {
            this.selectedInput.parentElement.parentElement.querySelector(
              ".feedsResultShipping"
            ).innerHTML = ``;
          }
        } else {
          this.selectedInput.parentElement.parentElement.querySelector(
            ".feedsResultShipping"
          ).innerHTML = ``;
        }
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },

    /**
     * @vuese
     *    choose shipping
     */
    changeShipping($event) {
      let input = $event.target;

      this.selectedInput = input;

      this.showBtnClicked = true;

      this.selectedInput.parentElement.parentElement.querySelector(
        ".pickupNoData"
      ).innerHTML = "";

      this.expanded = true;

      let myResult =
        this.selectedInput.parentElement.parentElement.querySelector(
          ".feedsResult"
        );

      let myPickupData =
        this.selectedInput.parentElement.parentElement.querySelector(
          ".supplierAddresses"
        );

      myPickupData.classList.remove("d-block");
      myPickupData.classList.add("d-none");

      let data = {
        country: this.form.country_id,
        governorate: this.form.region_id,
        city: this.form.city_id,
        address_uuid: localStorage.getItem("globalAddressUUID"),
        supplier_id: localStorage.getItem("s_id"),
      };
      suppliers
        .getShippingFees(data)
        .then((res) => {
          // console.log("res" , res.data.items.shepping_fee);
          this.sucessMsg(res.data.message);

          myResult.innerHTML =
            this.$t("profile.deleiveryFees") +
            Number(res.data.items.shepping_fee).toFixed(3) +
            " " +
            this.currency;

          myResult.classList.remove("d-none");
          myResult.classList.add("d-block");

          if (res.data.items == [] || res.data.items == "") {
            myResult.innerHTML = this.$t("profile.deliveryFeesText");
          }

          this.errors = [];
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.errors = err.items;
          this.errMsg(err.message);
        });
    },

    /**
     * @vuese
     *  choose pickup
     */
    changePickup($event, supplier) {
      let input = $event.target;

      this.selectedInput = input;

      this.showBtnClicked = false;

      this.getSupplierAddress(supplier.supplier_id);

      this.selectedInput.parentElement.nextElementSibling.classList.remove(
        "d-none"
      );
      this.selectedInput.parentElement.nextElementSibling.classList.add(
        "d-block"
      );

      this.selectedInput.parentElement.parentElement
        .querySelector(".feedsResult")
        .classList.remove("d-none");
      this.selectedInput.parentElement.parentElement
        .querySelector(".feedsResult")
        .classList.add("d-block");

      this.selectedInput.parentElement.parentElement.querySelector(
        ".feedsResult"
      ).innerHTML = `${this.$t("profile.deleiveryFees")} 0.000 ${
        this.currency
      }`;

      let myControler = this.$store.state.suppliers.suppliers;
      for (let index = 0; index < myControler.length; index++) {
        const element = myControler[index].supplier;
        // console.log("element" , element.id);

        if (element.shipping_type == 0 && element.id == supplier.supplier_id) {
          element.shipping_type = 1;
          element.point_of_sell_uuid =
            localStorage.getItem("addressUUID") !== null ||
            localStorage.getItem("addressUUID") !== undefined
              ? localStorage.getItem("addressUUID")
              : null;
        } else if (
          element.shipping_type == 1 &&
          element.id == supplier.supplier_id
        ) {
          element.shipping_type = 0;
          element.point_of_sell_uuid = null;
        }
      }
    },

    /**
     * @vuese
     *  store shipping data to store.js
     */
    shippingStore(supplier) {
      let newRating = {
        id: supplier.supplier_id,
        supplier_id: supplier.supplier_id,
        shipping_type: 0,
        coupon:
          localStorage.getItem("cou") !== null
            ? localStorage.getItem("cou")
            : "",
        point_of_sell_uuid: null,
      };
      this.$store.dispatch("suppliers/addSupplierToCart", {
        supplier: newRating,
      });

      let myControler = this.$store.state.suppliers.suppliers;
      for (let index = 0; index < myControler.length; index++) {
        const element = myControler[index].supplier;

        if (element.shipping_type == 0 && element.id == supplier.supplier_id) {
          element.shipping_type = 0;
          element.point_of_sell_uuid = null;
        } else if (
          element.shipping_type == 1 &&
          element.id == supplier.supplier_id
        ) {
          element.shipping_type = 0;
          element.point_of_sell_uuid = null;
        }
      }
    },

    orderType(supplier) {
      localStorage.setItem("s_id", supplier);
      localStorage.setItem("type", this.ratingNum);

      if (this.ratingNum.includes("0")) {
        this.deliverType = true;
      } else {
        this.deliverType = false;
      }
    },

    /**
     * @vuese
     *  get first shipping fees of suppliers
     */

    getShippingFeesExist() {
      let address_uuid = localStorage.getItem("globalAddressUUID");

      suppliers
        .getLoggedFirstShippingFees(address_uuid)
        .then((res) => {
          this.firstFees = res.data.items;
          this.sucessMsg(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          let error = Object.values(err)[2].data;
          this.errors = error.items;
          this.errMsg(error.message);
        });
    },

    /**
     * @vuese
     *  get shipping fee of supplier to user
     */
    getLoggedFirstShippingFees() {
      let address_uuid = localStorage.getItem("globalAddressUUID")
        ? localStorage.getItem("globalAddressUUID")
        : this.buyerUserData.uuid;
      suppliers
        .getFirstShippingFees(address_uuid, JSON.parse(this.selectedCountry).id)
        .then((res) => {
          // console.log("new", res);

          this.firstFees = res.data.items;
          this.sucessMsg(res.data.message);

          let arr = res.data.items;
          var size = Object.values(arr);
          // console.log("arr" , size);
          let myData = 0;
          for (let index = 0; index < size.length; index++) {
            const element = size[index].shipping_fee;
            // console.log(`element${index}`, element);
            myData += parseFloat(element);
          }

          // this.shippingCartFee = myData + 'reda';
          this.shippingCartFee = myData;
          this.totalPaymentReplacement += parseFloat(myData);
        })
        .catch((err) => {
          let error = Object.values(err)[2].data;
          this.errors = error.items;
          if (err.response.status !== 422) {
            this.errMsg(err.message);
          }
        });
    },

    /**
     * @vuese
     *  get shipping fee of supplier to guest
     */
    getGuestFirstShippingFees() {
      let data = {
        country: this.form.country_id,
        governorate: this.form.region_id,
        city: this.form.city_id,
        // address_line_1: this.form.address_line_1,
        address_line_1: this.form.address_line_1,
      };
      suppliers
        .getGuestFirstShippingFees(data)
        .then((res) => {
          this.firstFees = res.data.items;
          this.sucessMsg(res.data.message);
        })
        .catch((err) => {
          let error = Object.values(err)[2].data;
          this.errors = error.items;
          if (err.response.status !== 422) {
            this.errMsg(err.message);
          }
        });
    },
    /**
     * @vuese
     *  check SupplierFees
     */
    checkSupplierFees(supplier) {
      let data = {
        address_uuid: localStorage.getItem("globalAddressUUID"),
        supplier_id: supplier.supplier_id,
      };
      suppliers
        .checkSupplierFees(data)
        .then((res) => {
          this.sucessMsg(res.data.message);
        })
        .catch((err) => {
          let error = Object.values(err)[2].data;
          this.errors = error.items;
          this.errMsg(err.message);
        });
    },
    /**
     * @vuese
     *  toggle Options Select
     */
    toggleOptionsSelect() {
      this.showBtnClicked = true;
    },
    /**
     * @vuese
     *  close Options
     */
    closeOptions() {
      this.expanded = false;
    },

    /**
     * @vuese
     * user payment
     */

    async payment() {
      this.paymentFormData.address_uuid =
        localStorage.getItem("globalAddressUUID");
      this.checkoutSubmitted = true;

      // check if data exist first
      if (
        this.paymentFormData.address_uuid == "" ||
        !this.paymentFormData.address_uuid ||
        this.paymentFormData.address_uuid == undefined
      ) {
        this.paymentFormData.address_uuid =
          localStorage.getItem("globalAddressUUID");
      }
      if (
        this.paymentFormData.address_uuid == "undefined" ||
        this.paymentFormData.address_uuid == "null" ||
        this.paymentFormData.address_uuid == null ||
        !localStorage.getItem("globalAddressUUID")
      ) {
        this.paymentFormData.address_uuid = this.buyerUserData.uuid;
      }
      this.paymentFormData.coupons = this.existCoupons;
      if(this.paymentFormData?.payment_type == 'boubyan'){
        this.paymentFormData.card_number = this.card_number
        this.paymentFormData.card_expiry_month = this.card_expiry_month
        this.paymentFormData.card_expiry_year = this.card_expiry_year
        this.paymentFormData.cvv = this.cvv
      }
      
      suppliers
        .payment(this.paymentFormData)
        .then((res) => {
          this.sucessMsg(res.data.message);
          // if user select visa or wallet + visa
          if (
            this.paymentFormData.payment_type === "visa" ||
            this.paymentFormData.payment_type === "wallet_visa"
          ) {
            setTimeout(() => {
              this.$router.push({
                path: "/visa-checkout-details",
                query: {
                  order_serial: res.data.items.order_serial,
                  date: res.data.items.order.created_at,
                  total_price: this.totalPaymentReplacement,
                  payment_type: res.data.items.order.payment_type,
                  payment: res.data.items.order.payment,
                  uuid: res.data.items.order.uuid,
                  redirectURL: res.data.items.url,
                  wallet_paied: res.data.items.wallet_paied,
                  visa_paied: res.data.items.visa_paied,

                  // window.location.href = res.data.items.url;
                },
              });
              // location.reload();
              this.$store.dispatch("cart/getCartProducts");
            }, 500);
          } else {
            this.$router.push({
              path: "/CodBanckCheckoutDetails",
              query: {
                orderId: res.data.items.id,
              },
            });
            this.$store.dispatch("cart/getCartProducts");
          }
        })
        .catch((err) => {
          // if error in ckeckout process
          const errors = Object.values(err)[2].data;
          this.errors = errors.items;
          let addressesErrors = errors.items;
          if (
            addressesErrors.country ||
            addressesErrors.city ||
            addressesErrors.governorate ||
            addressesErrors.address_line_1
          ) {
            this.goTop();
          }
          this.errMsg(errors.message);
        })
        .finally(() => {
          this.checkoutSubmitted = false;
        });
    },

    /**
     * @vuese
     * guest  payment
     */
    guestPayment() {
      this.checkoutSubmitted = true;
      let data = {
        first_name: this.paymentFormData.first_name,
        last_name: this.paymentFormData.last_name,
        company_name: this.paymentFormData.company_name,
        email: this.paymentFormData.email,
        payment_type: this.paymentFormData.payment_type,
        phone: this.paymentFormData.phone,
        country: this.form.country_id,
        governorate: this.form.region_id,
        city: this.form.city_id,
        accept_terms: this.paymentFormData.accept_terms == true ? "1" : "0",
        building_number: this.form.building_number,
        floor: this.form.floor,
        block: this.form.block,
        pin_code: this.form.pin_code,
        address_line_1: this.form.address_line_1,
        notes: this.paymentFormData.comment,
        suppliers: this.mySuppliers.suppliers,
        country_code: this.paymentFormData.country_code,
        redirect_url: this.paymentFormData.redirect_url,
        coupons: this.existCoupons,
        avenue: this.form.avenue,
        // file: this.paymentFormData.file,
      };
      if(this.paymentFormData?.payment_type == 'boubyan'){
        data.card_number = this.card_number
        data.card_expiry_month = this.card_expiry_month
        data.card_expiry_year = this.card_expiry_year
        data.cvv = this.cvv
      }


      suppliers
        .guestPayment(data)
        .then((res) => {
          this.sucessMsg(res.data.message);
          if (this.paymentFormData.payment_type === "visa") {
            setTimeout(() => {
              this.$router.push({
                path: "/visa-checkout-details",
                query: {
                  order_serial: res.data.items.order.order_serial,
                  date: res.data.items.order.created_at,
                  total_price: this.totalPaymentReplacement,
                  payment_type: res.data.items.order.payment_type,
                  payment: res.data.items.order.payment,
                  uuid: res.data.items.order.uuid,
                  redirectURL: res.data.items.url,
                  // window.location.href = res.data.items.url;
                },
              });
              this.$store.dispatch("cart/getCartProducts");
            }, 500);
          } else {
            // console.log(res.data);
            if (this.buyerUserData) {
              setTimeout(() => {
                this.$router.push({
                  path: "/checkout-details",
                  query: {
                    order_serial: res.data.items.order_serial,
                    date: res.data.items.created_at,
                    total_price: this.totalPaymentReplacement,
                    payment_type: res.data.items.payment_type,
                    payment: res.data.items.payment,
                    uuid: res.data.items.uuid,
                    orderId: res.data.items.id,
                  },
                });
                this.$store.dispatch("cart/getCartProducts");
              }, 500);
            } else {
              setTimeout(() => {
                this.$router.push("/success-checkout");
                this.$store.dispatch("cart/getCartProducts");
              }, 500);
            }
          }
        })
        .catch((err) => {
          const errors = Object.values(err)[2].data;
          this.errors = errors.items;
          let addressesErrors = errors.items;
          if (
            addressesErrors.country ||
            addressesErrors.city ||
            addressesErrors.governorate ||
            addressesErrors.address_line_1
          ) {
            this.goTop();
          }
          this.errMsg(errors.message);
        })
        .finally(() => {
          this.checkoutSubmitted = false;
        });
    },
    /**
     * @vuese
     * payment GetAll Countires
     */
    paymentGetAllCountires() {
      auth.getAllCountires().then((res) => {
        this.paymentCountries = res.data.items;
      });
    },

    /**
     * @vuese
     *  getAllRegions
     */
    paymentGetAllRegions() {
      profile.getAllRegions(this.paymentFormData.country).then((res) => {
        this.paymentRegions = res.data.items;
        this.form.region_id = null;
        this.form.city_id = null;
      });
    },

    /**
     * @vuese
     *   Cities
     */
    paymentGetAllCities() {
      profile.getAllCities(this.paymentFormData.governorate).then((res) => {
        this.paymentCities = res.data.items;
        this.form.city_id = null;
      });
    },

    /**
     * @vuese
     *   get terms
     */
    getTerms() {
      auth.termsAndCondations().then((res) => {
        this.condations = res.data.items;
      });
    },

    /**
     * @vuese
     *   check terms as true
     */
    acceptMyTerms() {
      this.paymentFormData.accept_terms = true;
    },

    /**
     * @vuese
     *   get wallet data
     */
    getWallet() {
      profile.getWallet().then((res) => {
        this.walletData = res.data.items.balance;
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },

    /**
     * @vuese
     *   change payment coasts after change quantity of product
     */
    ChangeRateValue(res) {
      this.totalDiscount = res.data.items.total_cart.total_discount;

      this.total_cart = res.data.items;
      this.sucessMsg(res.data.message);
      this.couponChecked = true;

      if (res.data.items.total_cart.total_discount == 0) {
        this.totalDiscountReplacement = parseFloat(this.totalDiscount);
        this.totalPaymentReplacement = parseFloat(
          this.totalDiscountReplacement
        );
      } else {
        this.totalDiscountReplacement =
          parseFloat(this.totalDiscountReplacement) +
          parseFloat(res.data.items.total_cart.total_discount);
        this.totalPaymentReplacement = parseFloat(
          this.totalPayment +
            this.shippingCartFee -
            this.totalDiscountReplacement
        );
        // console.log("total subtotal" , this.totalPayment);
        // console.log("total discount",parseFloat(this.totalDiscountReplacement + this.shippingCartFee));
        // console.log("total " , this.totalPaymentReplacement);
      }
    },
    /**
     * @vuese
     *   change Coupon Status
     */
    changeCouponStatus(supplier) {
      let myControler = this.$store.state.suppliers.suppliers;
      for (let index = 0; index < myControler.length; index++) {
        const element = myControler[index].supplier;
        // console.log("element" , element.id);

        if (element.id == supplier.supplier_id) {
          this.totalDiscountReplacement -= element.couponDisc;
          this.totalPaymentReplacement = parseFloat(
            this.totalPayment +
              this.shippingCartFee -
              this.totalDiscountReplacement
          );

          element.couponDisc = 0;
        }
      }
    },

    /**
     *  @vuese
     *  got to the top of page
     */
    goTop() {
      window.scrollTo({
        top: 70,
        left: 0,
        behavior: "smooth",
      });
    },
    rotateElement(element) {
      let myElement = document.getElementById(`${element}`);
      let myElementParent = document.getElementById(`${element}`).parentElement;
      if (myElement.classList.contains("show")) {
        myElementParent.querySelector(".icon").classList.add("rotateIcon");
      } else {
        myElementParent.querySelector(".icon").classList.remove("rotateIcon");
      }
    },

    /**
     *  @vuese
     *   handleScroll
     */
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
      if (window.scrollY > 500) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    /**
     *  @vuese
     *   add new coupon
     */
    addCoupon() {
      // check if coupon exist and check if it's length > 1 (coupon not a blank)
      if (this.couponText && this.couponText.trim().length > 1) {
        this.validCoupon = true;

        if (this.coupons.length == 0) {
          let payload = {
            coupon: this.couponText,
          };

          suppliers
            .checkNewCoupon(payload)
            .then((res) => {
              // let coupons = [];

              // console.log(res.data.items.total_cart.total_discount);f
              if (res.status == 200) {
                this.validCoupon = true;
                this.existCoupons.push(this.couponText);
                this.coupons.unshift({
                  title: this.couponText,
                  value: res.data.items.total_cart.total_discount,
                });
                this.sucessMsg(res.data.message);
                this.couponText = null;
                this.couponError = null;
                // this.totalDiscountReplacement = res.data.items.total_cart.total_discount

                this.totalDiscount =
                  res.data.items.total_cart.total_discount.toFixed(3);

                if (res.data.items.total_cart.total_discount == 0) {
                  this.totalDiscountReplacement = parseFloat(
                    this.totalDiscount
                  );
                  this.totalDiscountReplacement =
                    parseFloat(this.totalDiscountReplacement) +
                    parseFloat(res.data.items.total_cart.total_discount);
                } else {
                  if (this.totalDiscountReplacement == 0) {
                    this.totalDiscountReplacement = parseFloat(
                      res.data.items.total_cart.total_discount
                    ).toFixed(3);
                  } else {
                    this.totalDiscountReplacement =
                      parseFloat(this.totalDiscountReplacement) +
                      parseFloat(res.data.items.total_cart.total_discount);
                  }
                  this.totalPaymentReplacement =
                    parseFloat(this.totalPaymentReplacement) -
                    parseFloat(res.data.items.total_cart.total_discount);
                  if (this.totalPaymentReplacement < 0) {
                    this.totalPaymentReplacement = 0;
                  }
                }
              } else {
                this.validCoupon = false;
              }
            })
            .catch((error) => {
              this.validCoupon = false;
              if (error) {
                const err = Object.values(error)[2].data;
                this.errors = err.items;
                this.errMsg(err.message);
                this.couponError = err.message;
                if (err.code == 401) {
                  this.loginFirst();
                }
              }
            });
        }

        // else {
        //   if (this.existCoupons.indexOf(this.couponText) > -1) {
        //     this.errMsg(this.$t("cart.couponExist"));
        //   } else {
        //     let payload = {
        //       coupon: this.couponText,
        //     };

        //     suppliers
        //       .checkNewCoupon(payload)
        //       .then((res) => {
        //         console.log("res", res);
        //         if (res.status == 200) {
        //           this.existCoupons.push(this.couponText);
        //           this.coupons.unshift({
        //             title: this.couponText,
        //             value: res.data.items.total_cart.total_discount,
        //           });

        //           this.sucessMsg(res.data.message);
        //           this.couponText = null;
        //           this.couponError = null;

        //           this.totalDiscount =
        //             res.data.items.total_cart.total_discount.toFixed(3);

        //           if (res.data.items.total_cart.total_discount == 0) {
        //             this.totalDiscountReplacement = parseFloat(
        //               this.totalDiscount
        //             );
        //             this.totalPaymentReplacement = parseFloat(
        //               this.totalDiscountReplacement
        //             );
        //           } else {
        //             if (this.totalDiscountReplacement == 0) {
        //               this.totalDiscountReplacement = parseFloat(
        //                 res.data.items.total_cart.total_discount
        //               ).toFixed(3);
        //             } else {
        //               this.totalDiscountReplacement =
        //                 parseFloat(this.totalDiscountReplacement) +
        //                 parseFloat(res.data.items.total_cart.total_discount);
        //             }
        //             this.totalPaymentReplacement =
        //               parseFloat(this.totalPaymentReplacement) -
        //               parseFloat(res.data.items.total_cart.total_discount);
        //             if (this.totalPaymentReplacement < 0) {
        //               this.totalPaymentReplacement = 0;
        //             }
        //           }
        //         }
        //       })
        //       .catch((error) => {
        //         if (error) {
        //           const err = Object.values(error)[2].data;
        //           this.errors = err.items;
        //           this.errMsg(err.message);
        //           this.couponError = err.message;
        //           if (err.code == 401) {
        //             this.loginFirst();
        //           }
        //         }
        //       });
        //   }
        // }
      }
    },

    /**
     *  @vuese
     *   remove exist coupon
     */
    removeMyCoupon(coupon, index) {
      this.coupons.splice(index, 1);
      for (let index = 0; index < this.existCoupons.length; index++) {
        const element = this.existCoupons[index];

        if (element == coupon.title) {
          this.existCoupons.splice(index, 1);
        }
      }
      // console.log("this.existCoupons remove", this.existCoupons);
      this.validCoupon = false;
      this.totalDiscountReplacement -= coupon.value;
      // console.log('coupon', coupon);
      // console.log('coupons', this.coupons);

      // this.totalPaymentReplacement = parseFloat(
      //   this.totalPayment +
      //   this.shippingCartFee -
      //   coupon.value
      // );
      this.totalPaymentReplacement += coupon.value;
      if (this.coupons.length == 0) {
        this.totalDiscountReplacement = 0;
      }
    },

    /**
     *  @vuese
     *   upload bank file when select bank payment method
     */
    uploadBankImage(event) {
      this.paymentFormData.file = event.target.files[0];
    },

    /**
     *  @vuese
     *   get Company Iban
     */

    getCompanyIban() {
      profile.companyIban().then((res) => {
        this.companyIban = res.data.items;
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    checPaymentAvailableTypes() {
      // cach test
      setTimeout(() => {
        let data = {
          payment_type: "cach",
        };
        suppliers.checPaymentAvailableTypes(data).then((res) => {
          if (res.status == 200) {
            if (res.data.items.status == 1) {
              this.cachMethodAvailable = true;
            }
          }
        });
        // .catch((err) => {
        //   console.log(err);
        // });
      }, 50);
      // wallet test
      setTimeout(() => {
        let data = {
          payment_type: "wallet",
        };
        suppliers.checPaymentAvailableTypes(data).then((res) => {
          if (res.status == 200) {
            if (res.data.items.status == 1) {
              this.walletMethodAvailable = true;
            }
          }
        });
        // .catch((err) => {
        //   console.log(err);
        // });
      }, 100);
      // visa test
      setTimeout(() => {
        let data = {
          payment_type: "visa",
        };
        suppliers.checPaymentAvailableTypes(data).then((res) => {
          if (res.status == 200) {
            if (res.data.items.status == 1) {
              this.visaMethodAvailable = true;
            }
          }
        });
        // .catch((err) => {
        //   console.log(err);
        // });
      }, 150);
      // bank test
      setTimeout(() => {
        let data = {
          payment_type: "bank",
        };
        suppliers.checPaymentAvailableTypes(data).then((res) => {
          if (res.status == 200) {
            if (res.data.items.status == 1) {
              this.bankMethodAvailable = true;
            }
          }
        });
        // .catch((err) => {
        //   console.log(err);
        // });
      }, 200);
      // wallet_visa test
      setTimeout(() => {
        let data = {
          payment_type: "wallet_visa",
        };
        suppliers.checPaymentAvailableTypes(data).then((res) => {
          if (res.status == 200) {
            if (res.data.items.status == 1) {
              this.walletVisaMethodAvailable = true;
            }
          }
        });
        // .catch((err) => {
        //   console.log(err);
        // });
      }, 250);
      // coupon test
      setTimeout(() => {
        let data = {
          payment_type: "coupon",
        };
        suppliers.checPaymentAvailableTypes(data).then((res) => {
          if (res.status == 200) {
            if (res.data.items.status == 1) {
              this.couponMethodAvailable = true;
            }
          }
        });
        // .catch((err) => {
        //   console.log(err);
        // });
      }, 250);
    },
    clearAll() {
      this.errors = {};
    },
    submitForm() {
      // Add validation here if needed
      if (this.validateForm()) {
        // Process form data
        alert('Form submitted successfully!');
      }
    },
    validateForm() {
      const isCardNumberValid = /^[0-9]{16}$/.test(this.card_number);
      const isExpiryMonthValid = /^(0[1-9]|1[0-2])$/.test(this.card_expiry_month);
      const isExpiryYearValid = /^[0-9]{4}$/.test(this.card_expiry_year);
      const isCvvValid = /^[0-9]{3}$/.test(this.cvv);

      if (!isCardNumberValid) {
        alert('Invalid Card Number');
      } else if (!isExpiryMonthValid) {
        alert('Invalid Expiry Month');
      } else if (!isExpiryYearValid) {
        alert('Invalid Expiry Year');
      } else if (!isCvvValid) {
        alert('Invalid CVV');
      }

      return isCardNumberValid && isExpiryMonthValid && isExpiryYearValid && isCvvValid;
    },
  },
  computed: {
    /**
     *  @vuese
     * check newPrice
     */
    newPrice() {
      return this.total_cart;
    },

    freeDeliveryStatus() {
      return sessionStorage.setItem("freeDelivery", this.freeDelivery);
    },

    cartTest() {
      return this.$store.state.cart;
    },
    checkType() {
      return localStorage.getItem("type").includes("1");
    },
    /**
     *  @vuese
     * get suppliers function
     */
    mySuppliers() {
      return this.$store.state.suppliers;
    },
    cartLength() {
      return this.$store.state.cart.cartLength;
    },
    onlinePaymentGuestSettingControl() {
      return this.$store?.state?.guestSettings?.tab_payment_status;
    },
    boubyanPaymentGuestSettingControl() {
      return this.$store?.state?.guestSettings?.boubyan_payment_status;
    },
  },
  created() {
    /**
     * @vuese
     *   sertting user address uuid
     */
    if (this.buyerUserData) {
      localStorage.setItem(
        "globalAddressUUID",
        this.buyerUserData.address_uuid
      );
    }
    this.goTop();
  },
};
</script>
<style lang="scss" scoped>
/**
    * component style
  */
@import "~/src/assets/scss/_cartStyle.scss";

/**
    * other component style
  */
@media screen and (max-width: 767px) {
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }

  table td {
    display: block;
    font-size: 0.8rem;
    border-top: none !important;
  }
}

// .float-right,
// .float-left {
//   line-height: 57px;
// }
.results-form {
  //background: rgba(236, 240, 241, 0.2);
}

.coupons-data-holder {
  span {
    background: #008000b3;
    padding: 2px 7px;
    text-align: center;
    font-size: 18px;
    word-break: break-all;
    margin-bottom: 3px;
    color: #fff;
    font-weight: bold;

    &:first-of-type {
      width: 50%;
    }
  }

  .removeCoupon {
    background: $main-color;
    color: #fff;
    cursor: pointer;
  }
}

.input-holder {
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    left: auto;
  }
}

.ar {
  .input-holder {
    button {
      left: 0;
      right: auto;
    }
  }
}

.icon {
  transition: all 0.3s ease-in-out;
}

.rotateIcon {
  transform: rotate(180deg);
}

.card-header {
  h5 {
    display: block;
    width: 100%;
  }
}

.new-border-main {
  border: 2px solid $main-color !important;
}

.time-holder {
  position: relative;
  text-align: center;
  padding: 0 5px;
}

.time-count {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 14px;
  background: $main-color;
  border-radius: 0 0 20px 20px;
  color: #fff;
  right: 10px;
  padding: 0 5px;

  //width:100%;
  @media (max-width: 767px) {
    display: block;
    height: 30px;
    line-height: 30px;
    width: 182px;
    margin: auto;
    position: relative;
    left: 5px;
  }
}
.visa-form {
  max-width: 400px;
  margin: 0 auto;
}
.visa-form label {
  display: block;
  margin-bottom: 4px;
  text-align: start;
}
.visa-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
}
</style>
