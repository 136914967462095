<template>
  <div class="p-2">
    <div
      id="apple-pay-button"
      v-if="isApplePaySupported"
      @click="initializeApplePay"
    ></div>
    <p v-else>{{$t('Apple Pay is not supported on this device/browser.')}}</p>
  </div>
</template>

<script>
export default {
  name: "ApplePayButton",
  props: {
    totalAmount: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String,
      default: "US",
    },
    currencyCode: {
      type: String,
      default: "USD",
    },
    label: {
      type: String,
      default: "Your Business Name",
    },
  },
  data() {
    return {
      isApplePaySupported: false,
    };
  },
  mounted() {
    this.isApplePaySupported =
      window.ApplePaySession && ApplePaySession.canMakePayments();
    if (this.isApplePaySupported) {
      this.styleApplePayButton();
    }
  },
  methods: {
    styleApplePayButton() {
      const button = document.getElementById("apple-pay-button");
      button.style.display = "inline-block";
      button.style.webkitAppearance = "-apple-pay-button";
      button.style.applePayButtonStyle = "black"; // Customize button style
      button.style.width = "200px";
      button.style.height = "44px";
    },
    async initializeApplePay() {
      const paymentRequest = {
        countryCode: this.countryCode,
        currencyCode: this.currencyCode,
        total: {
          label: this.label,
          amount: this.totalAmount,
        },
        supportedNetworks: ["visa", "masterCard", "amex"],
        merchantCapabilities: ["supports3DS"],
      };

      const session = new ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = async (event) => {
        try {
          const merchantSession = await this.validateMerchant(
            event.validationURL
          );
          session.completeMerchantValidation(merchantSession);
        } catch (error) {
          console.error("Merchant validation failed", error);
          session.abort();
        }
      };

      session.onpaymentauthorized = async (event) => {
        try {
          await this.processPayment(event.payment);
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          alert("Payment successful!");
        } catch (error) {
          console.error("Payment processing failed", error);
          session.completePayment(ApplePaySession.STATUS_FAILURE);
        }
      };

      session.begin();
    },
    async validateMerchant(validationURL) {
      const response = await fetch("/api/validate-merchant", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ validationURL }),
      });
      return response.json();
    },
    async processPayment(paymentData) {
      const response = await fetch("/api/process-payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ paymentData }),
      });
      return response.json();
    },
  },
};
</script>

<style scoped>
#apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: black;
  width: 200px;
  height: 44px;
}
</style>
